export const spacing = {
  xxxs: 4,
  xxs: 8,
  xss: 12,
  xs: 16,
  s: 24,
  m: 32,
  mm: 36,
  l: 48,
  ll: 56,
  xl: 64,
  xll: 72,
  xxl: 80,
  xxxl: 92,
  xxxll: 96,
  jumbo: 104,
}

/**
 * Override default for the grid layout
 *
 *
 * Breakpoints need to fit the full grid size,
 * plus some padding that needs to fit sidebar + gutter width.
 * Currently, padding is 116px (sidebar - 96px, gutter - 20px);
 *
 */

export const flexboxgrid = {
  // Defaults
  gridSize: 12, // columns
  gutterWidth: 1.25,
  outerMargin: 0, // rem
  mediaQuery: 'only screen',
  container: {
    lg: 90, // rem, 1440px / 16px
    md: 78, // rem, 1248px / 16px
  },
  breakpoints: {
    lg: 98, // rem, 1568px / 16px
    md: 86, // rem, 1376px / 16em
  },
}

/** Varoius container related settings (eg. border radius, ...) */
export const container = {
  borderRadius: {
    xss: 4,
    xs: 6,
    s: 8,
    m: 12,
    l: 18,
    xl: 22.5,
  },
}
