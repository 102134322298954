import BigNumber from 'bignumber.js'
import { allAssets, cardanoAssets } from 'crypto/config'
import {
  AnyAssetConfig,
  Asset,
  AssetType,
  ChainType,
  TransactionLog,
  Wallet,
} from 'crypto/interface'
import {
  CardanoTransaction,
  fetchTransactions as fetchCardanoTransactions,
  fetchTransactionsByUnit as fetchCardanoTransactionsByUnit,
} from 'crypto/lib/cardano/connector'
import {
  EthereumTransaction,
  fetchTransactions as fetchEthereumTransactions,
  fetchTransactionsByUnit as fetchEthereumTransactionsByUnit,
} from 'crypto/lib/ethereum/connector'
import { fromQuantity } from 'crypto/lib/util'
import compact from 'lodash/compact'
import { useCallback, useEffect, useState } from 'react'

export function useTransactions(wallet: Wallet, asset: Asset | undefined) {
  const [transactions, setTransactions] = useState<
    TransactionLog[] | undefined
  >()

  const fetchTransactions = useCallback(async (): Promise<TransactionLog[]> => {
    if (asset?.id && asset.chain) {
      const address = wallet.addresses[asset.chain]
      const config = allAssets.find((config) => config.id === asset.id)
      if (address && config) {
        return fetchAssetTransactions(address, asset.chain, config)
      }
    } else {
      return await fetchWalletTransactions(
        wallet.addresses.ethereum,
        wallet.addresses.polygon,
        wallet.addresses.cardano
      )
    }
    return []
  }, [asset?.chain, asset?.id, wallet.addresses])

  const updateTransactions = useCallback(async () => {
    const newTransactions = await fetchTransactions()
    const sorted = newTransactions.sort((a, b) => b.timestamp - a.timestamp)
    setTransactions(sorted)
  }, [fetchTransactions])

  useEffect(() => {
    updateTransactions()
  }, [updateTransactions])

  return transactions
}

async function fetchWalletTransactions(
  _ethereumAddress: string | undefined,
  polygonAddress: string | undefined,
  cardanoAddress: string | undefined
): Promise<TransactionLog[]> {
  const params = { sort: 'desc' } as const
  const polygonTransactionsP = polygonAddress
    ? fetchEthereumTransactions('polygon', polygonAddress, params)
    : Promise.resolve([])
  const cardanoTransactionsP = cardanoAddress
    ? fetchCardanoTransactions(cardanoAddress, params)
    : Promise.resolve([])

  const [polygonTransactions, cardanoTransactions] = await Promise.all([
    polygonTransactionsP,
    cardanoTransactionsP,
  ])
  return [
    ...polygonTransactions.map(toTransaction('polygon')),
    ...cardanoTransactions.map(toTransaction('cardano')),
  ]
}

async function fetchAssetTransactions(
  address: string,
  chain: ChainType,
  config: AnyAssetConfig
): Promise<TransactionLog[]> {
  const params = { sort: 'desc' } as const

  if (chain === 'cardano') {
    if (!config.cardano) return []

    const unit = config.cardano.policyId
      ? config.cardano.policyId + config.cardano.assetName
      : undefined
    const transactionsP = unit
      ? fetchCardanoTransactionsByUnit(address, unit, params)
      : fetchCardanoTransactions(address, params)
    return compact(
      (await transactionsP).map(
        toAssetTransaction(
          chain,
          unit ?? 'lovelace',
          config.cardano.decimals,
          config.priceTicker
        )
      )
    )
  } else {
    const chainConfig = config[chain]
    if (!chainConfig) return []

    const unit = chainConfig.address
    const transactionsP = unit
      ? fetchEthereumTransactionsByUnit(chain, address, unit, params)
      : fetchEthereumTransactions(chain, address, params)
    return (await transactionsP).map(
      toAssetTransaction(
        chain,
        unit ?? 'wei',
        chainConfig.decimals,
        config.priceTicker
      )
    )
  }
}

const toTransaction =
  (chain: ChainType) =>
  (tx: EthereumTransaction | CardanoTransaction): TransactionLog => {
    let amountQuantity: BigNumber | undefined
    let balanceQuantity: BigNumber | undefined
    let decimals = 0
    let priceTicker: string | undefined
    let assetIds: AssetType[] = []
    let type: TransactionLog['type'] =
      tx.direction === 'IN' ? 'Receive' : 'Send'
    let success = true

    if ('quantity' in tx && chain !== 'cardano') {
      if (tx.isError === '1') success = false
      if (tx.action !== 'TRANSFER') {
        type = 'Other'
      } else {
        amountQuantity = new BigNumber(tx.quantity)
      }

      const config =
        allAssets.find((config) => config[chain]?.address === tx.unit) ??
        allAssets.find((config) => config[chain]?.isCoin)!

      const balanceString = tx.balances.find(
        ({ unit }) => unit === (config[chain]?.address ?? 'wei')
      )?.quantity
      balanceQuantity = balanceString ? new BigNumber(balanceString) : undefined

      decimals = config[chain]?.decimals ?? 0
      priceTicker = config.priceTicker
      assetIds = [config.id]
    } else if ('assets' in tx && chain === 'cardano') {
      const assets = tx.assets.flatMap(({ values }) => values)
      const quantityMap = assets.reduce((map, { unit, quantity }) => {
        const current = map.get(unit) ?? new BigNumber(0)
        map.set(unit, current.plus(quantity))
        return map
      }, new Map<string, BigNumber>())

      const cardanoConfigs = cardanoAssets.flatMap((config) => {
        const unit = !config.cardano.isCoin
          ? config.cardano.policyId + config.cardano.assetName
          : 'lovelace'
        const amountQuantity = quantityMap.get(unit)
        const balanceQuantity = new BigNumber(
          tx.balances.find((balance) => balance.unit === unit)?.quantity ?? 0
        )
        if (!amountQuantity) return []
        return [{ config, amountQuantity, balanceQuantity }]
      })

      assetIds = cardanoConfigs.map(({ config }) => config.id)

      if (cardanoConfigs.length === 1) {
        const selected = cardanoConfigs[0]
        const config = selected.config
        amountQuantity = selected.amountQuantity
        balanceQuantity = selected.balanceQuantity
        decimals = config.cardano.decimals
        priceTicker = selected.config.priceTicker
      } else if (cardanoConfigs.length === 2) {
        const selected = cardanoConfigs.find(
          ({ config }) => !config.cardano.isCoin
        )!
        const config = selected.config
        amountQuantity = selected.amountQuantity
        balanceQuantity = selected.balanceQuantity
        decimals = config.cardano.decimals
        priceTicker = config.priceTicker
      }
    }

    amountQuantity = amountQuantity?.isNaN() ? undefined : amountQuantity
    balanceQuantity = balanceQuantity?.isNaN() ? undefined : balanceQuantity

    return {
      chain,
      hash: tx.hash,
      timestamp: parseInt(tx.timestamp),
      type,
      priceTicker,
      success,
      balance: balanceQuantity
        ? fromQuantity(balanceQuantity, decimals)
        : undefined,
      amount: amountQuantity
        ? fromQuantity(amountQuantity, decimals)
        : undefined,
      assetIds,
    }
  }

const toAssetTransaction =
  (chain: ChainType, unit: string, decimals: number, priceTicker: string) =>
  (tx: EthereumTransaction | CardanoTransaction): TransactionLog => {
    const balanceQuantity = new BigNumber(
      tx.balances.find((balance) => balance.unit === unit)?.quantity ?? 0
    )

    let amountQuantity: BigNumber | undefined
    let type: TransactionLog['type'] =
      tx.direction === 'IN' ? 'Receive' : 'Send'
    let success = true

    if ('quantity' in tx) {
      if (tx.isError === '1') success = false
      if (tx.action !== 'TRANSFER') {
        type = 'Other'
      } else {
        amountQuantity = new BigNumber(tx.quantity)
      }
    } else {
      amountQuantity = tx.assets.reduce(
        (sum, { values }) =>
          sum.plus(
            new BigNumber(
              values.find((value) => value.unit === unit)?.quantity ?? 0
            )
          ),
        new BigNumber(0)
      )
    }

    return {
      chain,
      hash: tx.hash,
      timestamp: parseInt(tx.timestamp),
      type,
      success,
      balance: fromQuantity(balanceQuantity, decimals),
      amount: amountQuantity
        ? fromQuantity(amountQuantity, decimals)
        : undefined,
      priceTicker,
      assetIds: [],
    }
  }
