import * as SL from '../serializationLib'
import { getBalanceFromValue } from './nami'
import { cardanoNetworkId } from 'crypto/config'
import { CryptoUserError } from 'crypto/interface'
import { Balance } from 'crypto/lib/balance'
import { uniq } from 'lodash'

interface EternlGlobal {
  enable(): Promise<EternlApi>
  isEnabled(): Promise<boolean>
}

interface EternlApi {
  getNetworkId(): Promise<number>
  getUsedAddresses(): Promise<string[]>
  getUnusedAddresses(): Promise<string[]>
  getChangeAddress(): Promise<string>
  getRewardAddress(): Promise<string>
  getUtxos(): Promise<string[]>
  getCollateral(): Promise<string[]>
  getBalance(): Promise<string>
  signTx(tx: string, partialSign?: boolean): Promise<string>
  signData(addr: string, sigStructure: string): Promise<string>
}

function getGlobal(): EternlGlobal | undefined {
  return (window as any)?.cardano?.eternl
}

async function tryGetGlobal<T>(
  f: (api: EternlApi) => Promise<T>,
  defaultValue: T
): Promise<T> {
  if (!globalApi) return defaultValue
  try {
    return await f(globalApi)
  } catch (error) {
    if (error instanceof Error) {
      if (error.message === 'account changed') {
        globalApi = await getGlobal()?.enable()
        return await tryGetGlobal(f, defaultValue)
      } else if (error.message === 'not connected') {
        globalApi = undefined
        return defaultValue
      }
    }
    throw error
  }
}

async function getAddressFromApi(): Promise<string | undefined> {
  return await tryGetGlobal(async (api) => {
    const address = await api.getChangeAddress()
    return SL.Address.from_bytes(Buffer.from(address, 'hex')).to_bech32()
  }, undefined)
}

export async function getAllAddresses(): Promise<string[]> {
  return await tryGetGlobal(async (api) => {
    const change = await api.getChangeAddress()
    const used = await api.getUsedAddresses()
    const unused = await api.getUnusedAddresses()
    const all = uniq([change, ...used, ...unused])
    return all.map((address) =>
      SL.Address.from_bytes(Buffer.from(address, 'hex')).to_bech32()
    )
  }, [])
}

function checkAddress(address: string | undefined) {
  if (!address) return
  try {
    return SL.Address.from_bech32(address).to_bech32()
  } catch {}
  try {
    return SL.Address.from_bytes(Buffer.from(address, 'hex')).to_bech32()
  } catch {}
}

let globalApi: EternlApi | undefined
let globalLastAddress: string | undefined
let globalIsCorrectNetwork = true
let globalOnStatus: ((address: string | undefined) => void) | undefined
let globalOnIncorrectNetwork:
  | ((desiredNetwork: string | undefined) => void)
  | undefined

async function updateAddress(isInitial = false) {
  const g = getGlobal()
  // Dont call globalOnStatus when we don't have eternl as it assumes that eternl is available
  if (!g) return

  const address = checkAddress(await getAddressFromApi())

  if (address !== globalLastAddress || isInitial) {
    globalLastAddress = address
    globalOnStatus?.(address)
  }

  const isCorrectNetwork = globalApi
    ? (await globalApi.getNetworkId()) === cardanoNetworkId
    : true
  if (isCorrectNetwork !== globalIsCorrectNetwork || isInitial) {
    globalIsCorrectNetwork = isCorrectNetwork
    const desiredNetwork = cardanoNetworkId === 0 ? 'testnet' : 'mainnet'
    globalOnIncorrectNetwork?.(isCorrectNetwork ? undefined : desiredNetwork)
  }
}

export function getAddress(): string {
  if (globalLastAddress) return globalLastAddress
  throw new CryptoUserError('ETERNL_NOT_LINKED')
}

export async function init(
  onStatus: (address: string | undefined) => void,
  onIncorrectNetwork: (desiredNetwork: string | undefined) => void
) {
  globalOnStatus = onStatus
  globalOnIncorrectNetwork = onIncorrectNetwork
  await updateAddress(true)
  setInterval(async () => {
    updateAddress().catch((e) => console.error(e))
  }, 1000)

  const eternlGlobal = getGlobal()
  if (!eternlGlobal) return
  if (!(await eternlGlobal.isEnabled())) return
  globalApi = await eternlGlobal.enable()
  await updateAddress()
}

export async function link(): Promise<'ok' | 'rejected' | 'unknown'> {
  const eternlGlobal = getGlobal()
  if (!eternlGlobal) return 'unknown'
  if (await eternlGlobal.isEnabled()) return 'ok'

  try {
    globalApi = await eternlGlobal.enable()
    return 'ok'
  } catch (error) {
    if ((error as any)?.code === -3) {
      return 'rejected'
    }
    return 'unknown'
  }
}

export async function getBalance(): Promise<Balance[]> {
  return await tryGetGlobal(async (api) => {
    const balanceHex = await api.getBalance()
    return getBalanceFromValue(balanceHex)
  }, [])
}

export async function getUtxos() {
  if (!globalApi) throw new CryptoUserError('ETERNL_NOT_LINKED')

  return globalApi.getUtxos()
}

export async function getCollateral() {
  if (!globalApi) throw new CryptoUserError('ETERNL_NOT_LINKED')
  const collateral = await globalApi.getCollateral()
  return collateral
    .slice(0, 1) // return 1 utxo at most
    .map((coll) =>
      SL.TransactionUnspentOutput.from_bytes(Buffer.from(coll, 'hex'))
    )
}

export async function signTransaction(
  transaction: SL.FixedTransaction,
  partial?: boolean
): Promise<SL.TransactionWitnessSet> {
  if (!globalApi) throw new CryptoUserError('ETERNL_NOT_LINKED')

  let witneses
  try {
    witneses = await globalApi.signTx(
      Buffer.from(transaction.to_bytes()).toString('hex'),
      partial
    )
  } catch (error: any) {
    if (error.code === 2 || error.code === -3) {
      throw new CryptoUserError('ETERNL_TRANSACTION_CANCELED')
    } else {
      throw new CryptoUserError('ETERNL_UNEXPECTED_ERROR')
    }
  }

  return SL.TransactionWitnessSet.from_bytes(Buffer.from(witneses, 'hex'))
}

export async function signMessage(
  message: string
): Promise<string | undefined> {
  if (!globalApi) throw new CryptoUserError('ETERNL_NOT_LINKED')

  const address = await getAddressFromApi()
  if (!address) throw new CryptoUserError('ETERNL_NOT_LINKED')

  return await globalApi.signData(
    address,
    Buffer.from(message, 'utf8').toString('hex')
  )
}
