import { ReactNode } from 'react'

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Error = 'error',
}

export interface NotificationInterface {
  /**
   * Unique identifier for each notification.
   */
  id: string
  /**
   * Notification title.
   *
   * Title is displayed above content and additionally emphasized.
   * Title is optional.
   */
  title?: ReactNode
  /**
   * Notification content.
   */
  content?: ReactNode
  /**
   * Type of a notification.
   */
  type: NotificationType
  /**
   * Notification will disappear after some time.
   *
   * This flag marks if notification should be persisted until dismissed by user manually.
   */
  persist?: boolean
  /**
   * Disables notification closing. 'X' will be removed from the notification, and only way
   * to close notification with timeout if persist is not `true` or with `removeNotification()`
   */
  disableClose?: boolean

  /**
   * Notification group name.
   * This will determin which of the group rules will be applied to the notifications in this group.
   * There is no global group config so rules will be taken from the last notification added to this group.
   *
   * If empty, no group rules will be applied to this notification.
   *
   * Group rules:
   *  - `groupMaxCount`
   */
  groupName?: string
  /** Define max number of notifications in group can be shown. Older notifications will be removed.
   *
   * If empty, defualts to `1`.
   */
  groupMaxCount?: number

  /**
   * Actions is a list of React nodes (buttons, links, icons, ...) which can be used to add interactivity to notification.
   *
   * Actions could be a part of content, but this way they can be rendered in a standard place and manner.
   */
  actions?: Array<ReactNode>
}

export type AddNotificationType = Omit<NotificationInterface, 'id'> & {
  id?: string
}
