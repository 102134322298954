import { maxLovelace } from './transaction'
import BigNumber from 'bignumber.js'
import { get, post, SortOrderFilterParams } from 'crypto/lib/backendHttpClient'
import { Balance } from 'crypto/lib/balance'
import { FixedTransaction } from 'crypto/lib/cardano/serializationLib'

export type Utxo = {
  address: string
  txHash: string
  value: string
  index: number
  tokens: {
    quantity: string
    asset: {
      policyId: string
      assetName: string
    }
  }[]
}

export type ProtocolParams = {
  linearFee: {
    minFeeA: string
    minFeeB: string
  }
  coinsPerUtxoSize: string
  coinsPerUtxoWord: string
  poolDeposit: string
  keyDeposit: string
  maxTxSize: number
  maxValSize: number
}

export type CardanoTransaction = {
  fee: string
  hash: string
  timestamp: string
  direction: 'IN' | 'OUT'
  balances: {
    quantity: string
    unit: string
  }[]
  assets: {
    address: string
    values: {
      quantity: string
      unit: string
    }[]
  }[]
}

export type CardanoBalance = {
  unit: string
  quantity: string
  pending_transaction: boolean
}

type MeldBackendAmount = {
  quantity: string
  unit: string
}[]

type MeldBackendUtxo = {
  tx_hash: string
  output_index: number
  amount: MeldBackendAmount
}

export type LockedStakingPosition = {
  opened_at: number
  closed_at: number
  unlocks_at: number
  period: 6 | 12
  staked_amount: number
  status: 'ACTIVE' | 'PENDING'
  tx_hash: string
  wallet_pkh: string
  ref: string
}

export type VestingPosition = {
  pending_withdrawal:
    | {
        opened_at: number
        tx_hash: string
        withdraw_amount: string
      }
    | undefined
  total_amount: string
  unvested_amount: string
  vested_amount: string
  withdrawable_amount: string
  withdrawn_amount: string
  output_json: string
  datum_json: string
  wallet_pkh: string
  ref: string
  original_ref: string
}

export async function fetchProtocolParams(): Promise<ProtocolParams> {
  const params = await get('api/cardano/protocolParams', {})

  return {
    linearFee: {
      minFeeA: params.min_fee_a.toString(),
      minFeeB: params.min_fee_b.toString(),
    },
    coinsPerUtxoSize: params.coins_per_utxo_size,
    coinsPerUtxoWord: params.coins_per_utxo_word,
    poolDeposit: params.pool_deposit,
    keyDeposit: params.key_deposit,
    maxTxSize: params.max_tx_size,
    maxValSize: Number.parseInt(params.max_val_size),
  }
}

export async function findUtxos(address: string): Promise<Utxo[]> {
  const utxos = await get('api/cardano/utxos', { address })
  return utxos.map((utxo: MeldBackendUtxo) => toUtxo(address, utxo))
}

function toUtxo(address: string, utxo: MeldBackendUtxo): Utxo {
  return {
    address: address,
    txHash: utxo.tx_hash,
    value: extractLovelaceValue(utxo.amount),
    index: utxo.output_index,
    tokens: amountsToTokens(utxo.amount),
  }
}

function extractLovelaceValue(amounts: MeldBackendAmount): string {
  return amounts.find((amount) => amount.unit == 'lovelace')!.quantity
}

function amountsToTokens(amounts: MeldBackendAmount) {
  return amounts
    .filter((amount) => amount.unit != 'lovelace')
    .map((amount) => {
      return {
        quantity: amount.quantity,
        asset: {
          policyId: amount.unit.substring(0, 56),
          assetName: amount.unit.substring(56),
        },
      }
    })
}

export async function openLockedStakingPosition(
  address: string,
  quantity: BigNumber,
  period: 6 | 12,
  txHex: string
): Promise<string> {
  console.log(txHex)
  return await post(
    'api/staking/locked/position/open',
    {
      address,
      staked_amount: quantity.toString(),
      pool: period,
    },
    {
      tx: txHex,
    }
  )
}

export async function fetchLockedStakingPosition(
  address: string,
  period: 6 | 12
): Promise<LockedStakingPosition | null> {
  return await get('api/staking/locked/position', { address, pool: period })
}

export async function fetchLockedStakingPositions(
  addresses: string[],
  period: 6 | 12
): Promise<LockedStakingPosition | null> {
  return await post('api/staking/locked/positions', { pool: period }, {addresses})
}

export async function fetchLockedStakingTotalAmountStaked(
  period: 6 | 12
): Promise<number> {
  const { total_amount_staked } = await get('api/staking/locked/stats', {
    pool: period,
  })
  return total_amount_staked
}

export async function vestingWithdraw(
  address: string,
  quantity: BigNumber,
  txHex: string
): Promise<string> {
  console.log(txHex)
  return await post(
    'api/vesting/withdraw',
    {
      address,
      amount: quantity.toString(),
    },
    {
      tx: txHex,
    }
  )
}

export async function fetchVestingPosition(
  addresses: string[]
): Promise<VestingPosition> {
  return await post('api/vesting/positions', {}, { addresses })
}

export async function submitTransaction(
  address: string,
  transaction: FixedTransaction
): Promise<string> {
  const txHex = Buffer.from(transaction.to_bytes()).toString('hex')
  return await post('api/cardano/submitTransaction', { address }, { tx: txHex })
}

export async function addMaxQuantity(
  balance: Balance[],
  walletId: 'nami' | 'eternl',
  address: string
): Promise<Balance[]> {
  const maxLovelaceQuantity = await maxLovelace(walletId, address)
  return balance.map((assetBalance: Balance): Balance => {
    let maxQuantity
    if (assetBalance.unit === 'lovelace') {
      maxQuantity = maxLovelaceQuantity
    } else {
      maxQuantity = assetBalance.quantity
    }
    return {
      ...assetBalance,
      maxQuantity,
    }
  })
}

export async function fetchBalance(
  walletId: 'nami' | 'eternl',
  address: string
): Promise<Balance[]> {
  const cardanoBalance: CardanoBalance[] = await get('api/cardano/balance', {
    address,
  })
  // convert raw `CardanoBalance` into `Balance`
  const balance = cardanoBalance.map(
    (cb: CardanoBalance): Balance => ({
      unit: cb.unit,
      quantity: cb.quantity,
      isPending: cb.pending_transaction,
      chain: 'cardano',
      maxQuantity: undefined,
    })
  )
  return await addMaxQuantity(balance, walletId, address)
}

export async function fetchTransactions(
  address: string,
  params: SortOrderFilterParams
): Promise<CardanoTransaction[]> {
  return await get('api/cardano/transactions', { address, ...params })
}

export async function fetchTransactionsByUnit(
  address: string,
  unit: string,
  params: SortOrderFilterParams
): Promise<CardanoTransaction[]> {
  return await get('api/cardano/transactions', { address, unit, ...params })
}

export async function fetchIsPending(address: string) {
  return (
    typeof (await get('api/cardano/pendingTransaction', { address }))
      ?.tx_hash === 'string'
  )
}
