import * as SL from './cardano/serializationLib'
import BigNumber from 'bignumber.js'
import { akamonApiUrl } from 'crypto/config'
import {
  AkamonTransactionStatus,
  ChainMap,
  CryptoUserError,
} from 'crypto/interface'
import JSONbig from 'json-bigint'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

function assert(condition: boolean, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message)
  }
}

// from the API response of /akamon/v2/list
export type AkamonBetaBridgingRequest = {
  requestId: string
  sourceChain: 'cardano' | 'polygon'
  destinationChain: 'cardano' | 'polygon'
  senderAddress: string
  receiverAddress: string
  event: 'wrap' | 'unwrap'
  token: 'ada' | 'matic' | 'meld' | 'usdt'
  amount: BigNumber
  status: AkamonTransactionStatus
  protocolFee: EncodedFeeValue
  networkFee: EncodedFeeValue
  requestTxId: string
  fulfillmentTxId: string
  submittedAt: number | undefined
  processedAt: number | undefined
  fulfilledAt: number | undefined
}

/**
 * `mustBeOneOf(allowedValues)(value)` returns one of the values from `allowedValues` that matches `value`.
 *
 * Matching is performed case-insensitively. If there are no matches, throws an error.
 *
 * Example: `mustBeOneOf(['ADA', 'MATIC'])('Ada') = 'ADA'`
 */
function mustBeOneOf<T extends string>(allowedValues: T[]) {
  return (value: unknown): T => {
    assert(
      typeof value === 'string',
      'value must be a string: ' + JSON.stringify({ value })
    )
    const result = allowedValues.find(
      (v) => v.toLowerCase() === value.toLowerCase()
    )
    assert(
      !!result,
      'value is not in the allowed list: ' +
        JSON.stringify({ value, allowedValues })
    )
    return result
  }
}

const FORMAT_STATUS = {
  pending: 'New',
  processing: 'Processing',
  done: 'Done',
} as const

// This is a quick-and-dirty way to resolve the following issue.
// https://github.com/MELD-labs/akamon-beta/issues/555
function __WORKAROUND__formatAddress(address: string) {
  if (!address.startsWith('addr')) return address
  const prefix = address.split('1')[0]
  const addressCsl = SL.Address.from_bech32(address)
  const baseAddressCsl = SL.BaseAddress.from_address(addressCsl)
  if (!baseAddressCsl) return address
  const enterpriseAddressCsl = SL.EnterpriseAddress.new(
    addressCsl.network_id(),
    baseAddressCsl.payment_cred()
  )
  const newAddressCsl = enterpriseAddressCsl.to_address()
  const newAddress = newAddressCsl.to_bech32(prefix)
  return newAddress
}

export async function listInAkamonBeta(
  addresses: string[]
): Promise<AkamonBetaBridgingRequest[]> {
  const response = await callAkamonBetaApi(
    'POST',
    '/akamon/v2/list',
    addresses.map(__WORKAROUND__formatAddress)
  )

  const requests = response?.data
  assert(Array.isArray(requests), 'invalid response')
  return requests.map((request) => {
    return {
      requestId: request.requestId,
      sourceChain: mustBeOneOf(['cardano', 'polygon'])(request.sourceChain),
      destinationChain: mustBeOneOf(['cardano', 'polygon'])(
        request.destinationChain
      ),
      senderAddress: request.senderAddress,
      receiverAddress: request.receiverAddress,
      event: mustBeOneOf(['wrap', 'unwrap'])(request.event),
      token: mustBeOneOf(['ada', 'matic', 'meld', 'usdt'])(request.token),
      amount: new BigNumber(request.amount),
      status:
        FORMAT_STATUS[
          mustBeOneOf(['pending', 'processing', 'done'])(request.status)
        ],
      protocolFee: request.protocolFee,
      networkFee: request.networkFee,
      requestTxId: request.requestTxId,
      fulfillmentTxId: request.fulfillmentTxId,
      submittedAt: request.submittedAt
        ? new Date(request.submittedAt).valueOf()
        : undefined,
      processedAt: request.processedAt
        ? new Date(request.processedAt).valueOf()
        : undefined,
      fulfilledAt: request.fulfilledAt
        ? new Date(request.fulfilledAt).valueOf()
        : undefined,
    }
  })
}

export type CardanoQuotation = {
  tx: SL.FixedTransaction
  quotation: {
    protocolFee: {
      volumeFee: BigNumber
    }
  }
}

export type EncodedFeeValue = {
  tag: 'Lovelace' | 'MaticWei'
  contents: string
}

export async function requestCardanoQuotation(params: {
  requestInfo: {
    destinationChain: string
    recipientAddress: string
    type: string
    token: string
    requestAmount: string
  }
  utxoInfo: {
    changeAddress: string
    inputs: [string, { address: string; value: object }][]
  }
}): Promise<CardanoQuotation> {
  const response = await callAkamonBetaApi(
    'POST',
    '/akamon/v2/quote/cardano?legacy',
    {
      requestInfo: params.requestInfo,
      utxoInfo: params.utxoInfo,
    }
  )

  if (typeof response.errMsg === 'string') {
    if (response.errMsg.startsWith('Error: Cannot balance Tx:')) {
      throw new CryptoUserError('CANNOT_BALANCE_BY_AKAMON_API')
    } else {
      throw new Error('response has error')
    }
  }

  const txCborHex = response?.tx?.cborHex
  const volumeFee = response?.quotation?.protocolFee?.volumeFee?.contents
  assert(typeof txCborHex === 'string' && typeof volumeFee === 'string')
  assert(!!SL.FixedTransaction, 'SL not ready')

  return {
    tx: SL.FixedTransaction.from_bytes(Buffer.from(txCborHex, 'hex')),
    quotation: {
      protocolFee: { volumeFee: new BigNumber(volumeFee) },
    },
  }
}

export type PolygonQuotation = {
  bridgeAddress: string
  quotation: {
    expirationBlock: BigNumber
    protocolFee: {
      volumeFee: BigNumber
      minAdaFee: {
        minLovelaceAmount: BigNumber
        feeValue: BigNumber
      }
    }
  }
  signature: string
  signer: string
}

export async function requestPolygonQuotation(params: {
  destinationChain: string
  recipientAddress: string
  type: string
  token: string
  requestAmount: string
}): Promise<PolygonQuotation> {
  const response = await callAkamonBetaApi('POST', '/akamon/v2/quote/polygon', {
    destinationChain: params.destinationChain,
    recipientAddress: params.recipientAddress,
    type: params.type,
    token: params.token,
    requestAmount: params.requestAmount,
  })

  if (typeof response.errMsg === 'string') {
    if (response.errMsg.startsWith('Error: Cannot balance Tx:')) {
      throw new CryptoUserError('CANNOT_BALANCE_BY_AKAMON_API')
    } else {
      throw new Error('response has error')
    }
  }

  const bridgeAddress = response?.bridgeAddress
  const volumeFee = response?.quotation?.protocolFee?.volumeFee?.contents
  const protocolFee = response?.quotation?.protocolFee
  const minLovelaceAmount = protocolFee?.minAdaFee?.minLovelaceAmount
  const feeValue = protocolFee?.minAdaFee?.feeValue?.contents
  const expirationBlock = response?.quotation?.expirationBlock
  const signature = response?.signature
  const signer = response?.signer

  assert(
    typeof bridgeAddress === 'string' &&
      typeof volumeFee === 'string' &&
      typeof minLovelaceAmount === 'string' &&
      typeof feeValue === 'string' &&
      typeof expirationBlock === 'string' &&
      typeof signature === 'string' &&
      typeof signer === 'string',
    'invalid response'
  )
  return {
    bridgeAddress,
    quotation: {
      expirationBlock: new BigNumber(expirationBlock),
      protocolFee: {
        volumeFee: new BigNumber(volumeFee),
        minAdaFee: {
          minLovelaceAmount: new BigNumber(minLovelaceAmount),
          feeValue: new BigNumber(feeValue),
        },
      },
    },
    signature,
    signer,
  }
}

export async function getAkamonBetaEta(): Promise<ChainMap<number>> {
  const eta = await callAkamonBetaApi('GET', '/akamon/v2/eta')
  const cardano = eta.wrapEtaSecondsCardano * 1000
  const polygon = eta.wrapEtaSecondsPolygon * 1000
  return {
    cardano: isNaN(cardano) ? undefined : cardano,
    polygon: isNaN(polygon) ? undefined : polygon,
  }
}

async function callAkamonBetaApi(
  method: string,
  path: string,
  body?: any
): Promise<any> {
  const response = await fetch(akamonApiUrl + path, {
    method,
    mode: 'cors',
    headers,
    body: body != null ? JSONbig.stringify(body) : undefined,
  })
  return JSONbig.parse(await response.text())
}
