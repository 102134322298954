import BigNumber from 'bignumber.js'
import {
  ChainType,
  Wallet,
  WalletId,
  AssetType,
  TransactionType,
  TransactionCreate,
  TransactionBuilder,
  VestingData,
} from 'crypto/interface'
import { MeldApi } from 'crypto/lib/cardano/meld_api'
import {
  createAkamonBetaTransaction,
  createTransaction,
  Transaction as RawTransaction,
} from 'crypto/lib/transaction'
import { useCallback, useMemo, useState } from 'react'
import { useDebounceAsync } from 'ui/Common/util/async/useDebounceAsync'

function useGenericTransaction<T extends TransactionType>(
  createTransaction: (
    ...params: Parameters<TransactionCreate<T>>
  ) => Promise<RawTransaction>
): TransactionBuilder<T> {
  const [state, setState] = useState<TransactionBuilder<T>['state']>('new')
  const [transaction, setTransaction] = useState<RawTransaction>()
  const [debounceAsyncContext] = useDebounceAsync()

  const transactionData = useMemo(() => {
    return {
      fee: transaction?.fee,
      amount: transaction?.amount,
      protocolFee: transaction?.protocolFee,
    }
  }, [transaction])

  const reset = useCallback(() => {
    debounceAsyncContext.cancel()
    setState('new')
    setTransaction(undefined)
  }, [debounceAsyncContext])

  const create = useCallback(
    async (...params: Parameters<TransactionCreate<T>>) => {
      setState('creating')
      setTransaction(undefined)

      return debounceAsyncContext
        .run(() => createTransaction(...params))
        .then((transaction) => {
          setTransaction(transaction)
          setState('created')
        })
        .catch((error) => {
          setState('new')

          throw error
        })
    },
    [createTransaction, debounceAsyncContext]
  ) as TransactionCreate<T>

  const submit = useCallback(
    async (password = '') => {
      if (state !== 'created' || !transaction)
        throw Error('Unexpected transaction state')
      setState('submitting')
      try {
        await transaction.submit(password)
        setTransaction(undefined)
        setState('submitted')
      } catch (error) {
        setState('created')
        throw error
      }
    },
    [state, transaction]
  )

  return useMemo(() => {
    return {
      state,
      transaction: transactionData,
      submit,
      create,
      reset,
    }
  }, [create, reset, state, submit, transactionData])
}

export function useSimpleTransaction(
  walletId: WalletId,
  chain: ChainType,
  assetId: AssetType
): TransactionBuilder<'transaction'> {
  const create = useCallback(
    (address: string, amount: string) =>
      createTransaction(walletId, chain, assetId, address, amount),
    [assetId, chain, walletId]
  )
  return useGenericTransaction<'transaction'>(create)
}

export function useAkamonBetaBridgeTransaction(): TransactionBuilder<'bridge'> {
  return useGenericTransaction<'bridge'>(createAkamonBetaTransaction)
}

export function useLockedStakeTransaction(
  period: 6 | 12,
  address: string | undefined,
  wallet: Wallet | undefined
): TransactionBuilder<'lockedStake'> {
  const create = useCallback(
    (amount: BigNumber) => {
      const walletId = wallet?.id
      if (
        !wallet ||
        !address ||
        !(walletId === 'nami' || walletId === 'eternl')
      )
        throw Error('Invalid transaction data passed!')
      const api = new MeldApi(wallet, address)
      return api.createLockedStakeTransaction(period, amount)
    },
    [period, address, wallet]
  )
  return useGenericTransaction<'lockedStake'>(create)
}

export function useLockedClaimTransaction(
  period: 6 | 12,
  address: string | undefined,
  wallet: Wallet | undefined
): TransactionBuilder<'lockedClaim'> {
  const create = useCallback(() => {
    const walletId = wallet?.id
    if (!wallet || !address || !(walletId === 'nami' || walletId === 'eternl'))
      throw Error('Invalid transaction data passed!')
    const api = new MeldApi(wallet, address)
    return api.createLockedClaimTransaction(period, address)
  }, [period, address, wallet])
  return useGenericTransaction<'lockedClaim'>(create)
}

export function useVestingTransaction(
  address: string | undefined,
  wallet: Wallet | undefined,
  vestingData: VestingData | undefined
): TransactionBuilder<'vesting'> {
  const create = useCallback(
    (amount: BigNumber) => {
      const walletId = wallet?.id
      if (
        !wallet ||
        !address ||
        !(walletId === 'nami' || walletId === 'eternl')
      )
        throw Error('Invalid transaction data passed!')
      if (!vestingData) throw Error('No vesting position exists!')
      const api = new MeldApi(wallet, address)
      return api.createWithdrawVestingTransaction(vestingData, amount)
    },
    [address, wallet, vestingData]
  )
  return useGenericTransaction<'vesting'>(create)
}
