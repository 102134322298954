import * as connector from '../connector'
import * as storage from '../storage'
import { mnemonicToEntropy } from 'bip39'
import cryptoRandomString from 'crypto-random-string'
import {
  BaseAddress,
  Bip32PrivateKey,
  decrypt_with_password,
  encrypt_with_password,
  EnterpriseAddress,
  hash_transaction,
  make_vkey_witness,
  NetworkInfo,
  RewardAddress,
  Credential,
  Transaction,
  TransactionWitnessSet,
  Vkeywitnesses,
  TransactionInput,
  BigNum,
  Value,
  Assets,
  AssetName,
  ScriptHash,
  MultiAsset,
  TransactionHash,
  TransactionOutput,
  Address,
  TransactionUnspentOutput,
} from 'crypto/lib/cardano/serializationLib'

export function getAddress(walletId: number): string {
  const address = storage.findStoredAddresses(walletId)?.baseAddress
  if (address) return address
  throw Error('Unexpected MELD wallet state')
}

export function signTransaction(
  walletId: number,
  password: string,
  transaction: Transaction
): TransactionWitnessSet {
  const { paymentKey } = fetchDecryptedKeys(walletId, password)

  const txWitnessSet = TransactionWitnessSet.new()
  const vkeyWitnesses = Vkeywitnesses.new()
  const txHash = hash_transaction(transaction.body())
  const vkey = make_vkey_witness(txHash, paymentKey)
  vkeyWitnesses.add(vkey)

  paymentKey.free()

  txWitnessSet.set_vkeys(vkeyWitnesses)

  return txWitnessSet
}

export function importWallet(
  name: string,
  seedPhrase: string,
  encryptionPassword: string
): number {
  const nextWalletId = storage.getNextWalletId()
  const addresses = addressesFromSeed(nextWalletId, seedPhrase, 'testnet')
  const encryptedRootKey = encryptRootKeyFromSeed(
    nextWalletId,
    seedPhrase,
    encryptionPassword
  )
  storage.storeWalletAddresses({
    name,
    timestamp: Date.now(),
    baseAddress: addresses.baseAddress,
    enterpriseAddress: addresses.enterpriseAddress,
    rewardAddress: addresses.rewardAddress,
    encryptedRootKey,
  })

  return nextWalletId
}

function encryptRootKeyFromSeed(
  walletId: number,
  seedPhrase: string,
  encryptionPassword: string
) {
  let entropy = mnemonicToEntropy(seedPhrase)
  const rootKey = Bip32PrivateKey.from_bip39_entropy(
    Buffer.from(entropy, 'hex'),
    Buffer.from('')
  )

  entropy = ''
  seedPhrase = ''

  const encryptedRootKey = encryptWithPassword(
    walletId,
    encryptionPassword,
    rootKey.as_bytes()
  )
  rootKey.free()

  return encryptedRootKey
}

function encryptWithPassword(
  walletId: number,
  password: string,
  rootKeyBytes: Uint8Array
) {
  const rootKeyHex = Buffer.from(rootKeyBytes).toString('hex')
  const passwordHex = Buffer.from(password).toString('hex')
  const salt = cryptoRandomString({ length: 2 * 32 })
  const nonce = cryptoRandomString({ length: 2 * 12 })
  return encrypt_with_password(passwordHex, salt, nonce, rootKeyHex)
}

function fetchDecryptedKeys(walletId: number, password: string) {
  const cardanoStorage = storage.findStoredAddresses(walletId)
  try {
    const rootKey = Bip32PrivateKey.from_bytes(
      Buffer.from(
        decryptWithPassword(password, cardanoStorage!.encryptedRootKey),
        'hex'
      )
    )

    const accKey = rootKey
      .derive(harden(1852)) // purpose
      .derive(harden(1815)) // coin type
      .derive(harden(walletId)) // acc index

    return {
      paymentKey: accKey.derive(0).derive(0).to_raw_key(),
      stakeKey: accKey.derive(2).derive(0).to_raw_key(),
    }
  } catch (e) {
    throw Error('Wrong password')
  }
}

function decryptWithPassword(password: string, encryptedKeyHex: string) {
  const passwordHex = Buffer.from(password).toString('hex')
  try {
    return decrypt_with_password(passwordHex, encryptedKeyHex)
  } catch (err) {
    throw new Error('Wrong password')
  }
}

function harden(num: number): number {
  return 0x80000000 + num
}

function getNetworkInfo(network: 'mainnet' | 'testnet') {
  switch (network) {
    case 'mainnet':
      return NetworkInfo.mainnet()
    case 'testnet':
      return NetworkInfo.testnet_preprod()
  }
}

function addressesFromSeed(
  walletId: number,
  seed: string,
  network: 'mainnet' | 'testnet'
) {
  const keys = seedToKeys(walletId, seed)

  const networkId = getNetworkInfo(network).network_id()
  const { utxoPubKey, stakeKey } = keys
  const baseAddress = BaseAddress.new(
    networkId,
    Credential.from_keyhash(utxoPubKey.to_raw_key().hash()),
    Credential.from_keyhash(stakeKey.to_raw_key().hash())
  )

  const enterpriseAddress = EnterpriseAddress.new(
    networkId,
    Credential.from_keyhash(utxoPubKey.to_raw_key().hash())
  )

  const rewardAddress = RewardAddress.new(
    networkId,
    Credential.from_keyhash(stakeKey.to_raw_key().hash())
  )

  return {
    baseAddress: baseAddress.to_address().to_bech32(),
    enterpriseAddress: enterpriseAddress.to_address().to_bech32(),
    rewardAddress: rewardAddress.to_address().to_bech32(),
  }
}

function seedToKeys(walletId: number, seedPhrase: string) {
  const entropy = mnemonicToEntropy(seedPhrase)
  const rootKey = Bip32PrivateKey.from_bip39_entropy(
    Buffer.from(entropy, 'hex'),
    Buffer.from('')
  )
  const accountKey = rootKey
    .derive(harden(1852)) // purpose
    .derive(harden(1815)) // coin type
    .derive(harden(walletId)) // account #0

  const utxoPubKey = accountKey
    .derive(0) // external
    .derive(0)
    .to_public()
  const stakeKey = accountKey
    .derive(2) // chimeric
    .derive(0)
    .to_public()

  return { rootKey, accountKey, utxoPubKey, stakeKey }
}

export async function getUtxos(walletId: number) {
  const address = getAddress(walletId)
  const utxos = await connector.findUtxos(address)
  return utxos.map((utxo) =>
    Buffer.from(generateUnspentOutput(utxo, address).to_bytes()).toString('hex')
  )
}

function generateUnspentOutput(utxo: connector.Utxo, address: string) {
  const input = TransactionInput.new(
    TransactionHash.from_bytes(Buffer.from(utxo.txHash, 'hex')),
    utxo.index
  )

  const output = TransactionOutput.new(
    Address.from_bech32(address),
    utxoToValue(utxo)
  )

  return TransactionUnspentOutput.new(input, output)
}

function utxoToValue(utxo: connector.Utxo) {
  const multiAsset = MultiAsset.new()

  const policies = Array.from(
    new Set(utxo.tokens.map((token) => token.asset.policyId))
  )

  policies.forEach((policy) => {
    const policyAssets = utxo.tokens.filter(
      (token) => token.asset.policyId === policy
    )
    const assetsValue = Assets.new()
    policyAssets.forEach((asset) => {
      assetsValue.insert(
        AssetName.new(Buffer.from(asset.asset.assetName, 'hex')),
        BigNum.from_str(asset.quantity)
      )
    })
    multiAsset.insert(
      ScriptHash.from_bytes(Buffer.from(policy, 'hex')),
      assetsValue
    )
  })
  const value = Value.new(BigNum.from_str(utxo.value ? utxo.value : '0'))
  if (utxo.tokens.length > 0) value.set_multiasset(multiAsset)
  return value
}
