// Extracted from `akamon-polygon-contracts/abis/Bridge.json`
// https://github.com/MELD-labs/akamon-beta/blob/main/akamon-polygon-contracts/abis/Bridge.json
// This object contain a subset of fields from `Bridge.json`.
export const akamonBetaAbi = [
  {
    inputs: [
      { internalType: 'uint8', name: '_destinationChain', type: 'uint8' },
      { internalType: 'bytes32', name: '_token', type: 'bytes32' },
      { internalType: 'uint256', name: '_requestAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_volumeFee', type: 'uint256' },
      { internalType: 'uint256', name: '_minLovelaceAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_minLovelaceFee', type: 'uint256' },
      { internalType: 'uint256', name: '_expirationBlock', type: 'uint256' },
      { internalType: 'string', name: '_recipientAddress', type: 'string' },
      { internalType: 'address', name: '_quoter', type: 'address' },
      { internalType: 'bytes', name: '_signature', type: 'bytes' },
    ],
    name: 'lockToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint8', name: '_destinationChain', type: 'uint8' },
      { internalType: 'bytes32', name: '_token', type: 'bytes32' },
      { internalType: 'uint256', name: '_requestAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_volumeFee', type: 'uint256' },
      { internalType: 'uint256', name: '_minLovelaceAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_minLovelaceFee', type: 'uint256' },
      { internalType: 'uint256', name: '_expirationBlock', type: 'uint256' },
      { internalType: 'string', name: '_recipientAddress', type: 'string' },
      { internalType: 'address', name: '_quoter', type: 'address' },
      { internalType: 'bytes', name: '_signature', type: 'bytes' },
    ],
    name: 'burnWrappedToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
] as const
