import styled, { css } from 'styled-components'
import { CloseIcon } from 'ui/Icons/components/CloseIcon'

export const NotificationsContainer = styled.div`
  position: fixed;
  top: ${(p) => p.theme.spacing.s}px;
  right: ${(p) => p.theme.spacing.m}px;
  /* TODO: calculate proper width */
  width: 450px;
  z-index: 150;
`

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: ${(p) => p.theme.spacing.xxs}px;
  padding: 20px 28px 20px 32px;
  margin-top: ${(p) => p.theme.spacing.xss}px;
`

const iconsCommon = css`
  flex: 0 0 ${(p) => p.theme.spacing.xss}px;
  height: ${(p) => p.theme.spacing.m}px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
`

export const IconWrapper = styled.div`
  ${iconsCommon};
  margin-right: ${(p) => p.theme.spacing.xs}px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10; /* make content take most of the space */
`

export const ActionsWrapper = styled.div`
  margin-top: ${(p) => p.theme.spacing.xss}px;
  display: flex;
  justify-content: left;
  gap: ${(p) => p.theme.spacing.xss}px;
`

export const CloseWrapper = styled.div`
  ${iconsCommon};
  margin-left: ${(p) => p.theme.spacing.xs}px;
  cursor: pointer;
`

export const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(p) => p.theme.colors.grey.grey7};
`
