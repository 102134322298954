import { NotificationInterface } from 'ui/Common/components/Notifications/interface'

const GROUP_MAX_COUNT_DEFAULT = 1

/**
 * Add new notification to the list and apply list rules.
 *
 * Notification is first added to the list of existing notifications
 * and then other rules are applied which can change this list.
 */
export function addNewNotification(
  newNotification: NotificationInterface,
  list: NotificationInterface[]
): NotificationInterface[] {
  let newList = list

  newList = pushNewNotification(newNotification, newList)

  newList = applyGroupMaxCount(newNotification, newList)

  return newList
}

/**
 * Add new notification to the list.
 *
 * Notification is "new" if there is no notification with the same ID
 * in the current list.
 *
 * If notification is not new the original list nothing is changed and the orginal list is returned.
 */
export function pushNewNotification(
  newNotification: NotificationInterface,
  list: NotificationInterface[]
): NotificationInterface[] {
  if (list.find((n) => n.id === newNotification.id)) {
    return list
  }

  // push new notifications to the list
  return [...list, newNotification]
}

/**
 * Apply group max count rule
 *
 * Allow only "max" number of notifications with the same group. The oldest notifications
 * are removed first (FIFO).
 *
 * If max number is not given then it defualts to 1.
 */
export function applyGroupMaxCount(
  newNotification: NotificationInterface,
  list: NotificationInterface[]
): NotificationInterface[] {
  const groupName = newNotification.groupName

  // no group config
  if (groupName === null) {
    return list
  }

  // group max count
  let groupItemCounter =
    newNotification.groupMaxCount ?? GROUP_MAX_COUNT_DEFAULT
  // iterate in reverse so we deprecate the oldest messages once max count has been exceeded
  const filteredList = list.reduceRight((accum, item) => {
    if (item.groupName == null || item.groupName !== groupName) {
      accum.unshift(item)
    } else {
      if (groupItemCounter > 0) {
        accum.unshift(item)
      }
      groupItemCounter--
    }

    return accum
  }, [] as NotificationInterface[])

  // if there is no more space in the group that means that we have removed some notifications
  // iow. we have changed the list
  if (groupItemCounter < 0) {
    return filteredList
  }

  return list
}
