import mainnet from './mainnet'
import preview from './preview'
import preprod from './preprod'
import testnet from './testnet'
import {
  AnyAssetConfig,
  AnyCardanoAssetConfig,
  AnyEthereumAssetConfig,
  AnyPolygonAssetConfig,
  hasCardano,
  hasEthereum,
  hasPolygon,
} from 'crypto/interface'

export const pollTime = 60000

export let allAssets: AnyAssetConfig[]

export let cardanoAssets: AnyCardanoAssetConfig[]
export let ethereumAssets: AnyEthereumAssetConfig[]
export let polygonAssets: AnyPolygonAssetConfig[]

export let ethChainId: string
export let polygonChainId: string
export let cardanoNetworkId: number

export const adaDecimals = 6
export const maticDecimals = 18

export let meldBackendUrl: string
export let akamonApiUrl: string
export let meldApiUrl: string

export let isVasil: boolean

export function initCryptoConfig(env: {
  network: string
  meldBackendUrl: string
  akamonApiUrl: string
  meldApiUrl: string
}) {
  const isMainnet = env.network === 'mainnet'
  const isPreview = env.network === 'preview'
  const isPreprod = env.network === 'preprod'

  allAssets = isMainnet ? mainnet : isPreview ? preview : isPreprod ? preprod : testnet

  cardanoAssets = allAssets.filter(hasCardano)
  ethereumAssets = allAssets.filter(hasEthereum)
  polygonAssets = allAssets.filter(hasPolygon)

  ethChainId = isMainnet ? '0x1' : '0x5'
  polygonChainId = isMainnet ? '0x89' : '0x13881'
  cardanoNetworkId = isMainnet ? 1 : 0

  meldBackendUrl = env.meldBackendUrl
  akamonApiUrl = env.akamonApiUrl
  meldApiUrl = env.meldApiUrl

  isVasil = !isMainnet
}

// Default environment, if `crypto` is used without initializing it this will be default env values
initCryptoConfig({
  network: 'testnet',
  meldBackendUrl: '',
  akamonApiUrl: '',
  meldApiUrl: '',
})
