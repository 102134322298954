import { useIntervalPolling } from './util'
import { AssetType } from 'crypto/interface'
import { fetchUnitPrices, UnitPrices } from 'crypto/lib/unitPrice'
import keys from 'lodash/keys'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

const unitPricesContext = createContext<UnitPrices>({})

export function UnitPricesProvider({ children }: { children: ReactNode }) {
  const [unitPrices, setUnitPrices] = useState<UnitPrices>({})

  const updateUnitPrices = useCallback(async () => {
    const newUnitPrices = await fetchUnitPrices()
    const unitIds = keys(newUnitPrices) as AssetType[]
    setUnitPrices((unitPrices) => {
      const isEqual = unitIds.reduce((isEqual, key) => {
        if (!isEqual) return false
        const lhs = newUnitPrices[key]
        const rhs = unitPrices[key]
        if (lhs === undefined && rhs === undefined) return true
        if (!lhs || !rhs) return false
        return lhs.eq(rhs)
      }, true)
      return isEqual ? unitPrices : newUnitPrices
    })
  }, [])

  useIntervalPolling(updateUnitPrices)

  return (
    <unitPricesContext.Provider value={unitPrices}>
      {children}
    </unitPricesContext.Provider>
  )
}

export function useUnitPrices(): UnitPrices {
  return useContext(unitPricesContext)
}
