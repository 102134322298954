/**
 * Please don't import this file from components.
 */
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const ENV_PREFIX = '!ENVSTART!'
const ENV_SUFFIX = '!ENVEND!'

/**
 * In static runtime mode, desired format is ENV_PREFIX|VAR_NAME|VALUE|END_PREFIX
 */

function isPlaceholderFormat(value: string) {
  return value.startsWith(ENV_PREFIX) && value.endsWith(ENV_SUFFIX)
}

/**
 * Unwraps the "wrapped" env variable.
 */
function parsePlaceholder(value: string) {
  const reg = new RegExp(`${ENV_PREFIX}[^!]+!(.*)![^!]+${ENV_SUFFIX}`, 'g')
  const res = reg.exec(value)
  return res ? res[1] ?? '' : ''
}

function ensureEnv(varName: string, value: string, defaultValue?: string) {
  if (value) {
    return value
  } else if (defaultValue !== undefined) {
    return defaultValue
  } else {
    console.error(`Environment variable ${varName} not set!`)
    return ''
  }
}

function parse(varName: string, defaultValue?: string): string {
  const rawValue = publicRuntimeConfig[varName]
  if (isPlaceholderFormat(rawValue)) {
    return ensureEnv(varName, parsePlaceholder(rawValue), defaultValue)
  } else {
    return ensureEnv(varName, rawValue, defaultValue)
  }
}

export function getEnv() {
  const env = {
    meldBackendUrl: parse('MELD_BACKEND_URL'),
    akamonApiUrl: parse('AKAMON_API_URL'), // akamon-api is a service of akamon-beta to quote fee
    meldApiUrl: parse('MELD_API_URL'), // meld-api is the replacement of pab to build vesting & staking txs
    network: parse('NETWORK'),
    environment: parse('ENVIRONMENT'),
    commitSHA: parse('COMMIT_SHA'),
    meldappVersion: parse('MELDAPP_VERSION', '1.2.0'),
    matomoUrl: parse('MATOMO_URL'),
    matomoSiteId: parse('MATOMO_SITE_ID'),
    sentryDsn: parse('SENTRY_DSN'),
  }
  console.info('Initialized env.')
  console.dir(env)
  return env
}

export default getEnv()
