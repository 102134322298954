import { BalanceProvider } from './balance'
import { SelectedWalletProvider } from './selectedWallet'
import { UnitPricesProvider } from './unitPrice'
import { WalletsProvider } from './wallets'
import { ReactNode } from 'react'

export * from './akamon'
export * from './asset'
export * from './meldState'
export * from './staking'
export * from './transaction'
export * from './transactionHistory'
export * from './util'
export * from './vesting'
export {
  useLinkMetamask,
  useLinkNami,
  useLinkEternl,
  useCreateMeldWallet,
} from './wallets'

export function CryptoProvider({ children }: { children: ReactNode }) {
  return (
    <UnitPricesProvider>
      <SelectedWalletProvider>
        <WalletsProvider>
          <BalanceProvider>{children}</BalanceProvider>
        </WalletsProvider>
      </SelectedWalletProvider>
    </UnitPricesProvider>
  )
}
