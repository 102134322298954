export const light = {
  green: {
    green6: '#68D04F',
  },
  red: {
    red6: '#FD003D',
    red4: '#FFA48C',
  },
  blue: {
    blue6: '#355dff',
  },
  grey: {
    grey10: '#121826',
    grey9: '#212936',
    grey8: '#394150',
    grey7: '#4D5461',
    grey6: '#6C727F',
    grey5: '#9EA3AE',
    grey4: '#D3D5DA',
    grey3: '#E5E6EB',
    grey2: '#F4F4F6',
    grey1: '#F9FAFB',
  },
  white: {
    white100: '#FFFFFF',
    white80: '#FFFFFFCC',
    white60: '#FFFFFF99',
    white40: '#FFFFFF66',
    white20: '#FFFFFF33',
    white10: '#FFFFFF1A',
    white5: '#FFFFFF0D',
  },
  additional: {
    orange: '#FFC07A',
    redPink: '#FF7D7D',
    bg: '#edeef0',
    yellow: '#FFAE68',
  },
}

export const dark = {
  ...light,
  // TODO: Add Dark theme colors
}

// Color paths in the theme object
export type ColorKey =
  | 'green.green6'
  | 'red.red6'
  | 'red.red4'
  | 'blue.blue6'
  | 'grey.grey10'
  | 'grey.grey9'
  | 'grey.grey8'
  | 'grey.grey7'
  | 'grey.grey6'
  | 'grey.grey5'
  | 'grey.grey4'
  | 'grey.grey3'
  | 'grey.grey2'
  | 'grey.grey1'
  | 'white.white100'
  | 'white.white80'
  | 'white.white60'
  | 'white.white40'
  | 'white.white20'
  | 'white.white10'
  | 'white.white5'
  | 'additional.bg'
  | 'additional.orange'
  | 'additional.redPink'
  | 'additional.yellow'
