import React from 'react'

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    fill="#FFF"
    {...props}
  >
    <path
      d="M9.813.188a.604.604 0 0 0-.876 0L5 4.124 1.062.187a.604.604 0 0 0-.875 0 .604.604 0 0 0 0 .875L4.126 5 .187 8.938a.604.604 0 0 0 0 .874c.126.126.25.188.438.188a.567.567 0 0 0 .438-.188L5 5.876l3.938 3.938a.675.675 0 0 0 .437.187.675.675 0 0 0 .438-.188.604.604 0 0 0 0-.874L5.874 5l3.938-3.938a.604.604 0 0 0 0-.875z"
      fillRule="nonzero"
    />
  </svg>
)
