import {
  allAssets,
  cardanoAssets,
  ethereumAssets,
  polygonAssets,
} from '../config'
import { UnitPrices } from './unitPrice'
import { fromQuantity } from './util'
import BigNumber from 'bignumber.js'
import {
  Asset,
  Wallet,
  ChainType,
  AnyAssetConfig,
  ChainAssetConfig,
} from 'crypto/interface'

export type Balance = {
  unit: string
  quantity: string
  maxQuantity: string | undefined
  isPending: boolean
  chain: ChainType
}

type ConfigWithQuantity = {
  config: AnyAssetConfig
  chainConfig: ChainAssetConfig
  chain: ChainType
  quantity: string | undefined
  maxQuantity: string | undefined
  isPending: boolean
}

function createCardanoConfigsWithQuantity(
  cardanoBalance: Balance[]
): ConfigWithQuantity[] {
  return cardanoAssets.map((config) => {
    const balance = cardanoBalance.find((balance) =>
      config.cardano.isCoin
        ? balance.unit === 'lovelace'
        : config.cardano.policyId + config.cardano.assetName === balance.unit
    )

    return {
      config,
      chainConfig: config.cardano,
      chain: 'cardano',
      quantity: balance?.quantity,
      maxQuantity: balance?.maxQuantity,
      isPending: balance?.isPending ?? false,
    }
  })
}

function createEthereumConfigsWithQuantity(
  ethereumBalance: Balance[]
): ConfigWithQuantity[] {
  return ethereumAssets.map((config) => {
    const balance = ethereumBalance.find((balance) =>
      config.ethereum.isCoin
        ? balance.unit === 'wei'
        : config.ethereum.address === balance.unit
    )

    return {
      config,
      chainConfig: config.ethereum,
      chain: 'ethereum',
      quantity: balance?.quantity,
      maxQuantity: balance?.maxQuantity,
      isPending: balance?.isPending ?? false,
    }
  })
}

function createPolygonConfigsWithQuantity(
  polygonBalance: Balance[]
): ConfigWithQuantity[] {
  return polygonAssets.map((config) => {
    const balance = polygonBalance.find((balance) =>
      config.polygon.isCoin
        ? balance.unit === 'wei'
        : config.polygon.address === balance.unit
    )

    return {
      config,
      chainConfig: config.polygon,
      chain: 'polygon',
      quantity: balance?.quantity,
      maxQuantity: balance?.maxQuantity,
      isPending: balance?.isPending ?? false,
    }
  })
}

function createAssets(
  configsWithQuantity: ConfigWithQuantity[],
  unitPrices: UnitPrices
): [Asset[], BigNumber] {
  const assets = configsWithQuantity.map(
    ({ config, chainConfig, quantity, maxQuantity, chain, isPending }) => {
      const unitBalance = fromQuantity(quantity || 0, chainConfig.decimals)
      const maxAmount = maxQuantity
        ? fromQuantity(maxQuantity, chainConfig.decimals)
        : undefined
      const unitPrice = unitPrices[config.id]
      const fiatBalance = unitPrice?.times(unitBalance)

      return {
        id: config.id,
        isCoin: chainConfig.isCoin,
        name: chainConfig.name,
        unitBalance,
        maxAmount,
        fiatBalance,
        unitPrice,
        decimals: chainConfig.decimals,
        change: undefined,
        ticker: chainConfig.ticker,
        hasGraph: false,
        isPending,
        isStakeable: false,
        isBridgableToCardano: chain !== 'cardano' && !!config.cardano,
        isBridgableToPolygon: chain !== 'polygon' && !!config.polygon,
        isBridgableToEthereum: chain !== 'ethereum' && !!config.ethereum,
        chain,
      }
    }
  )

  const balance = assets.reduce(
    (sum, { fiatBalance }) => (fiatBalance ? sum.plus(fiatBalance) : sum),
    new BigNumber(0)
  )
  return [assets, balance]
}

export function getMeldWallet(
  id: number,
  address: string,
  name: string,
  timestamp: number,
  cardanoBalance: Balance[] = [],
  unitPrices: UnitPrices
): Wallet {
  const cardanoAssets = createCardanoConfigsWithQuantity(cardanoBalance)
  const [assets, fiatBalance] = createAssets(cardanoAssets, unitPrices)
  return {
    id,
    name,
    timestamp,
    fiatBalance,
    isPending: !!assets.find(({ isPending }) => isPending),
    addresses: {
      ethereum: undefined,
      polygon: undefined,
      cardano: address,
    },
    assets,
  }
}

export function getMetamaskWallet(
  address: string,
  timestamp: number,
  polygonBalance: Balance[] = [],
  unitPrices: UnitPrices
): Wallet {
  const polygonAssets = createPolygonConfigsWithQuantity(polygonBalance)
  const [assets, fiatBalance] = createAssets(polygonAssets, unitPrices)
  return {
    id: 'metamask',
    name: 'Metamask wallet',
    timestamp,
    fiatBalance,
    isPending: !!assets.find(({ isPending }) => isPending),
    addresses: {
      ethereum: undefined,
      polygon: address,
      cardano: undefined,
    },
    assets,
  }
}

export function getNamiWallet(
  address: string,
  timestamp: number,
  cardanoBalance: Balance[] = [],
  unitPrices: UnitPrices
): Wallet {
  const cardanoAssets = createCardanoConfigsWithQuantity(cardanoBalance)
  const [assets, fiatBalance] = createAssets(cardanoAssets, unitPrices)
  return {
    id: 'nami',
    name: 'Nami wallet',
    timestamp,
    fiatBalance,
    isPending: !!assets.find(({ isPending }) => isPending),
    addresses: {
      ethereum: undefined,
      polygon: undefined,
      cardano: address,
    },
    assets,
  }
}

export function getEternlWallet(
  address: string,
  timestamp: number,
  cardanoBalance: Balance[] = [],
  unitPrices: UnitPrices
): Wallet {
  const cardanoAssets = createCardanoConfigsWithQuantity(cardanoBalance)
  const [assets, fiatBalance] = createAssets(cardanoAssets, unitPrices)
  return {
    id: 'eternl',
    name: 'Eternl wallet',
    timestamp,
    fiatBalance,
    isPending: !!assets.find(({ isPending }) => isPending),
    addresses: {
      ethereum: undefined,
      polygon: undefined,
      cardano: address,
    },
    assets,
  }
}

export function getAllAssets(unitPrices: UnitPrices): Asset[] {
  const assetsWithQuantity = allAssets.map((config) => ({
    config,
    chainConfig: config[config.nativeChain]!,
    chain: config.nativeChain,
    quantity: undefined,
    maxQuantity: undefined,
    isPending: false,
  }))
  const [assets] = createAssets(assetsWithQuantity, unitPrices)
  return assets
}
