import {
  AnyAssetConfig,
  NativeCardanoAssetConfig,
  NativePolygonAssetConfig,
} from 'crypto/interface'
  
const meld: NativeCardanoAssetConfig = {
  priceTicker: 'MELD',
  id: 'meld',
  nativeChain: 'cardano',
  cardano: {
    isCoin: false,
    policyId: '4ebcb13f01e735976486b3c39072eed6d9f362e916fb04fe2c37f951',
    assetName: '744d454c44',
    ticker: 'MELD',
    name: 'MELD',
    isStakeable: true,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'mMELD',
    name: 'mMELD',
    address: '0xaC9EbCF83ed2eeDe572306e2D8B322Ba57314392',
    decimals: 6,
    image: 'https://d13tn3ghacljy8.cloudfront.net/mMELD.png',
  },
  ethereum: undefined,
}

const ada: NativeCardanoAssetConfig = {
  priceTicker: 'ADA',
  id: 'ada',
  nativeChain: 'cardano',
  cardano: {
    isCoin: true,
    policyId: undefined,
    assetName: undefined,
    name: 'ADA',
    ticker: 'ADA',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'mADA',
    name: 'mADA',
    address: '0x677e4F7b95f8ace849252896A734a6718FD25f1C',
    decimals: 6,
    image: 'https://meld-public.s3.eu-west-1.amazonaws.com/mADA.png',
  },
  ethereum: undefined,
}

const matic: NativePolygonAssetConfig = {
  priceTicker: 'MATIC',
  id: 'matic',
  nativeChain: 'polygon',
  cardano: {
    isCoin: false,
    policyId: 'd1d955255cf9fc282ea68b5e9e41c1f1e0c479d2429cdad3210cc2c2',
    assetName: '6d4d41544943',
    ticker: 'mMATIC',
    name: 'mMATIC',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: true,
    ticker: 'MATIC',
    name: 'MATIC',
    address: undefined,
    decimals: 18,
    image: undefined,
  },
  ethereum: undefined,
}

// it's Ethereum really
const usdt: NativePolygonAssetConfig = {
  priceTicker: 'USDT',
  id: 'usdt',
  nativeChain: 'polygon',
  cardano: {
    isCoin: false,
    policyId: 'd1d955255cf9fc282ea68b5e9e41c1f1e0c479d2429cdad3210cc2c2',
    assetName: '6d55534454',
    ticker: 'mUSDT',
    name: 'mUSDT',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'USDT',
    name: 'USDT',
    address: '0x3813e82e6f7098b9583fc0f33a962d02018b6803',
    decimals: 6,
    image: 'https://d13tn3ghacljy8.cloudfront.net/USDT.png',
  },
  ethereum: undefined,
}

const preview: AnyAssetConfig[] = [
  meld,
  ada,
  matic,
  //  usdt
]
export default preview
