import { CSSProperties } from 'styled-components'

const shorthandPropMap: Record<string, string> = {
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',

  mt: 'marginTop',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',

  pt: 'paddingTop',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
}

/**
 * Component that implements this interface has support for
 * basic element sizing configuration via prop shorthands.
 */
export interface ISizable {
  m?: CSSProperties['margin']
  p?: CSSProperties['padding']

  w?: CSSProperties['width']
  h?: CSSProperties['height']

  mx?: CSSProperties['margin']
  my?: CSSProperties['margin']

  px?: CSSProperties['padding']
  py?: CSSProperties['padding']

  mt?: CSSProperties['marginTop']
  mb?: CSSProperties['marginBottom']
  ml?: CSSProperties['marginLeft']
  mr?: CSSProperties['marginRight']

  pt?: CSSProperties['paddingTop']
  pb?: CSSProperties['paddingBottom']
  pl?: CSSProperties['paddingLeft']
  pr?: CSSProperties['paddingRight']
}

export function getStylesFromProps(p: ISizable) {
  const ret: Record<string, string | number> = {}

  for (const shortKey in p) {
    const value = p[shortKey as keyof ISizable]
    if (value === undefined) continue

    if (shortKey in shorthandPropMap) ret[shorthandPropMap[shortKey]] = value

    if (/(p|m)(x|y)/.test(shortKey)) {
      const stringValue = typeof value === 'number' ? `${value}px` : value
      const d = shortKey[0] === 'p' ? 'padding' : 'margin'
      if (shortKey[1] === 'x') {
        ret[`${d}Left`] = stringValue
        ret[`${d}Right`] = stringValue
      } else {
        ret[`${d}Top`] = stringValue
        ret[`${d}Bottom`] = stringValue
      }
    }
  }

  return ret
}
