import { ethChainId, polygonChainId } from '../../config'
import { fromQuantity } from '../util'
import { EIP1193Provider } from './EIP-1193'
import { akamonBetaAbi } from './akamonBetaAbi'
import * as EthersAddress from '@ethersproject/address'
import * as EthersBigNumber from '@ethersproject/bignumber'
import * as EthersBytes from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import {
  Web3Provider,
  JsonRpcSigner,
  TransactionRequest,
} from '@ethersproject/providers'
import * as EthersStrings from '@ethersproject/strings'
import BigNumber from 'bignumber.js'
import {
  ChainType,
  CryptoUserError,
  EthereumAssetConfig,
  PolygonAssetConfig,
} from 'crypto/interface'

const ETH_DECIMALS = 18

function getProvider() {
  return window.ethereum!
}

function getAddress() {
  return window.ethereum?.selectedAddress
}

export async function init(onStatus: (account: string | undefined) => void) {
  const ethereum = await waitForMetamask(500)
  if (!ethereum) return

  const permissions = (await ethereum.request({
    method: 'wallet_getPermissions',
  })) as string[]

  if (permissions.length > 0) {
    const accounts = (await getProvider().request({
      method: 'eth_requestAccounts',
    })) as string[]
    onStatus(accounts.length > 0 ? accounts[0] : undefined)
  } else {
    onStatus(undefined)
  }

  ethereum.on('connect', () => onStatus(ethereum.selectedAddress))
  ethereum.on('accountsChanged', (accounts) => onStatus(accounts[0]))
  ethereum.on('disconnect', () => onStatus(undefined))
}

async function waitForMetamask(
  timeout = 3000
): Promise<EIP1193Provider | undefined> {
  if (window.ethereum) {
    return window.ethereum.isMetaMask ? window.ethereum : undefined
  }

  let handled = false

  return new Promise<EIP1193Provider | undefined>((resolve) => {
    window.addEventListener('ethereum#initialized', handleEthereum, {
      once: true,
    })

    setTimeout(handleEthereum, timeout)

    function handleEthereum() {
      if (handled) return
      handled = true

      window.removeEventListener('ethereum#initialized', handleEthereum)

      resolve(window.ethereum?.isMetaMask ? window.ethereum : undefined)
    }
  })
}

export async function link(): Promise<'ok' | 'rejected' | 'unknown'> {
  const ethereum = await waitForMetamask()
  if (!ethereum) return 'unknown'
  if (ethereum.selectedAddress) return 'ok'

  let accounts: string[] = []
  try {
    accounts = (await getProvider().request({
      method: 'eth_requestAccounts',
    })) as string[]
    if (!accounts.length) return 'unknown'
  } catch (error) {
    const code = (error as any).code
    if (code === 4001) {
      return 'rejected'
    }
    return 'unknown'
  }

  return 'ok'
}

async function changeMetamaskChainId(chainId: string) {
  if (window.ethereum?.chainId === chainId) return

  try {
    await promptChainSwitch(chainId)
  } catch (switchError: any) {
    if (switchError.code === 4902) {
      await addPolygonChain()
      await promptChainSwitch(chainId)
    } else {
      throw switchError
    }
  }
}

export async function changeMetamaskChain(chain: ChainType) {
  if (chain === 'cardano') return

  return await changeMetamaskChainId(
    chain === 'polygon' ? polygonChainId : ethChainId
  )
}

function promptChainSwitch(chainId: string) {
  return getProvider()
    .request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    })
    .catch((error: any) => {
      if (error.code === 4001) {
        throw new CryptoUserError('NETWORK_SWITCH_REJECTED')
      } else {
        throw error
      }
    })
}

function addPolygonChain() {
  return getProvider()
    .request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: polygonChainId,
          chainName: 'Polygon Testnet',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon.testnet.app.meld.com/'],
          blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
        },
      ],
    })
    .catch((error: any) => {
      if (error.code === 4001) {
        throw new CryptoUserError('NETWORK_SWITCH_REJECTED')
      } else {
        throw error
      }
    })
}

async function createTransaction(
  chainId: string,
  createRequest: (signer: JsonRpcSigner) => Promise<TransactionRequest>
): Promise<{
  fee: BigNumber
  submit: () => Promise<string>
}> {
  await changeMetamaskChainId(chainId)

  const signer = new Web3Provider(window.ethereum!).getSigner()

  const unsigned = await createRequest(signer)

  const gas = await signer.estimateGas(unsigned).catch((error) => {
    if ((error as any).code === -32603 && error.data.code === -32000) {
      throw new CryptoUserError('INSUFFICIENT_MATIC_FUND')
    } else {
      throw error
    }
  })

  const gasPrice = await signer.getGasPrice()
  const feeWei = gas.mul(gasPrice)
  const fee = fromQuantity(feeWei.toString(), ETH_DECIMALS)

  return {
    fee,
    async submit() {
      await changeMetamaskChainId(chainId)

      try {
        return (await signer.sendTransaction(unsigned)).hash
      } catch (error) {
        if ((error as any).code === 4001) {
          throw new CryptoUserError('METAMASK_TRANSACTION_CANCELED')
        } else {
          throw error
        }
      }
    },
  }
}

export async function createEthTransaction(
  chainId: string,
  address: string,
  quantity: BigNumber
): Promise<{
  fee: BigNumber
  submit: () => Promise<string>
}> {
  return await createTransaction(chainId, (signer) =>
    signer.populateTransaction({
      to: address,
      value: quantity.toString(),
    })
  )
}

const ERC20TransferAbi = [
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        name: '',
        type: 'bool',
      },
    ],
    type: 'function',
  },
] as const

export async function createERC20Transaction(
  chainId: string,
  contract: string,
  address: string,
  quantity: BigNumber
): Promise<{
  fee: BigNumber
  submit: () => Promise<string>
}> {
  return await createTransaction(chainId, (signer) =>
    new Contract(
      contract,
      ERC20TransferAbi,
      signer
    ).populateTransaction.transfer(address, quantity.toString())
  )
}

export async function wrapToken({
  token,
  bridgeAddress,
  requestAmount,
  volumeFee,
  minAmount,
  minFee,
  expirationBlock,
  recipientAddress,
  quoterAddress,
  signatureHex,
}: {
  token: string
  bridgeAddress: string
  requestAmount: BigNumber
  volumeFee: BigNumber
  minAmount: BigNumber
  minFee: BigNumber
  expirationBlock: BigNumber
  recipientAddress: string
  quoterAddress: string
  signatureHex: string
}): Promise<{
  fee: BigNumber
  submit: () => Promise<string>
}> {
  return await createTransaction(polygonChainId, async (signer) => {
    const contract = new Contract(bridgeAddress, akamonBetaAbi, signer)
    const value = volumeFee
      .plus(minFee)
      .plus(token === 'MATIC' ? requestAmount : 0)
      .toString() // TODO: is this correct
    return await contract.populateTransaction.lockToken(
      0, // destinationChain (uint8), 0=Cardano, 1=Polygon
      EthersStrings.formatBytes32String(token), // token (bytes32)
      EthersBigNumber.BigNumber.from(requestAmount.toString()), // _requestAmount (uint256)
      EthersBigNumber.BigNumber.from(volumeFee.toString()), // _volumeFee (uint256)
      EthersBigNumber.BigNumber.from(minAmount.toString()), // _minLovelaceAmount (uint256)
      EthersBigNumber.BigNumber.from(minFee.toString()), // _minLovelaceFee (uint256)
      EthersBigNumber.BigNumber.from(expirationBlock.toString()), // _expirationBlock (uint256)
      recipientAddress, // _recipientAddress (string)
      EthersAddress.getAddress(quoterAddress), // _quoter (address)
      EthersBytes.arrayify(signatureHex, { allowMissingPrefix: true }), // _signature (bytes)
      { value }
    )
  })
}

export async function unwrapToken({
  token,
  bridgeAddress,
  requestAmount,
  volumeFee,
  minAmount,
  minFee,
  expirationBlock,
  recipientAddress,
  quoterAddress,
  signatureHex,
}: {
  token: string
  bridgeAddress: string
  requestAmount: BigNumber
  volumeFee: BigNumber
  minAmount: BigNumber
  minFee: BigNumber
  expirationBlock: BigNumber
  recipientAddress: string
  quoterAddress: string
  signatureHex: string
}): Promise<{
  fee: BigNumber
  submit: () => Promise<string>
}> {
  return await createTransaction(polygonChainId, async (signer) => {
    const contract = new Contract(bridgeAddress, akamonBetaAbi, signer)
    const value = volumeFee.plus(minFee).toString() // TODO: is this correct
    return await contract.populateTransaction.burnWrappedToken(
      0, // destinationChain (uint8), 0=Cardano, 1=Polygon
      EthersStrings.formatBytes32String(token), // token (bytes32)
      EthersBigNumber.BigNumber.from(requestAmount.toString()), // _requestAmount (uint256)
      EthersBigNumber.BigNumber.from(volumeFee.toString()), // _volumeFee (uint256)
      EthersBigNumber.BigNumber.from(minAmount.toString()), // _minLovelaceAmount (uint256)
      EthersBigNumber.BigNumber.from(minFee.toString()), // _minLovelaceFee (uint256)
      EthersBigNumber.BigNumber.from(expirationBlock.toString()), // _expirationBlock (uint256)
      recipientAddress, // _recipientAddress (string)
      EthersAddress.getAddress(quoterAddress), // _quoter (address)
      EthersBytes.arrayify(signatureHex, { allowMissingPrefix: true }), // _signature (bytes)
      { value }
    )
  })
}

export async function estimatedWeiFee(): Promise<BigNumber> {
  const gasPrice = (await getProvider().request({
    method: 'eth_gasPrice',
  })) as string
  // TODO: this is a rule of thumb amount that passes in all transactions in MELDapp
  const gas = 42000
  return new BigNumber(gas).times(gasPrice)
}

export async function signMessage(message: string) {
  return (await getProvider().request({
    method: 'personal_sign',
    params: [message, getAddress()],
  })) as string
}

export async function watchAsset(
  config: PolygonAssetConfig | EthereumAssetConfig
): Promise<boolean> {
  const ethereum = await waitForMetamask()
  if (!ethereum) return Promise.reject(false)

  return ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: config.address,
        symbol: config.ticker,
        decimals: config.decimals,
        image: config.image,
      },
    },
  }) as Promise<boolean>
}
