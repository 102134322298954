import { meldBackendUrl } from 'crypto/config'

export type SortOrderFilterParams = {
  sort?: 'asc' | 'desc'
  page?: number
  count?: number
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export async function get(
  path: string,
  queryParamsObject: Record<string, any>
) {
  const result = await await (
    await fetch(meldBackendUrl + path + queryParams(queryParamsObject), {
      method: 'GET',
      mode: 'cors',
      headers,
    })
  ).json()
  if (result.ok) {
    return result.data
  } else {
    throw Error(result.data)
  }
}

function queryParams(paramsObject: Record<string, any>) {
  const params = Object.entries(paramsObject)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return '?' + params
}

export async function post(
  path: string,
  queryParamsObject: Record<string, any>,
  body: any
) {
  const result = await (
    await fetch(meldBackendUrl + path + queryParams(queryParamsObject), {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify(body),
    })
  ).json()
  if (result.ok) {
    return result.data
  } else {
    throw Error(JSON.stringify(result.data))
  }
}
