import {
  AnyAssetConfig,
  NativeCardanoAssetConfig,
  NativePolygonAssetConfig,
} from 'crypto/interface'

const meld: NativeCardanoAssetConfig = {
  priceTicker: 'MELD',
  id: 'meld',
  nativeChain: 'cardano',
  cardano: {
    isCoin: false,
    policyId: '6ac8ef33b510ec004fe11585f7c5a9f0c07f0c23428ab4f29c1d7d10',
    assetName: '4d454c44',
    ticker: 'MELD',
    name: 'MELD',
    isStakeable: true,
    decimals: 6,
    image: undefined,
  },
  polygon: undefined,
  ethereum: undefined,
}

const ada: NativeCardanoAssetConfig = {
  priceTicker: 'ADA',
  id: 'ada',
  nativeChain: 'cardano',
  cardano: {
    isCoin: true,
    policyId: undefined,
    assetName: undefined,
    name: 'ADA',
    ticker: 'ADA',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: undefined,
  ethereum: undefined,
}

const matic: NativePolygonAssetConfig = {
  priceTicker: 'MATIC',
  id: 'matic',
  nativeChain: 'polygon',
  cardano: undefined,
  polygon: {
    isCoin: true,
    ticker: 'MATIC',
    name: 'MATIC',
    address: undefined,
    decimals: 18,
    image: undefined,
  },
  ethereum: undefined,
}

const mainnet: AnyAssetConfig[] = [meld, ada, matic]
export default mainnet
