import { useIntervalPolling } from './util'
import { useBaseWallets } from './wallets'
import { WalletId } from 'crypto/interface'
import { Balance } from 'crypto/lib/balance'
import { addMaxQuantity, fetchIsPending } from 'crypto/lib/cardano/connector'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import * as nami from 'crypto/lib/cardano/wallet/nami'
import { fetchBalance as fetchEthereumBalance } from 'crypto/lib/ethereum/connector'
import isEqual from 'lodash/isEqual'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

type State = Partial<Record<WalletId, Balance[]>>
type StateDispatch = (updateState: (state: State) => State) => void

const balanceContext = createContext<[State, StateDispatch]>([{}, () => {}])

export function BalanceProvider({ children }: { children: ReactNode }) {
  const [state, rawStateDispatch] = useState({})
  const baseWallets = useBaseWallets()

  const stateDispatch = useCallback<StateDispatch>(
    (updateState) =>
      rawStateDispatch((state: State) => {
        const newState = updateState(state)
        if (isEqual(state, newState)) return state
        return newState
      }),
    []
  )

  const refreshMetamaskBalance = useCallback(async () => {
    if (baseWallets.metamask.status !== 'linked') {
      return stateDispatch((state) => ({ ...state, metamask: [] }))
    }
    const balance = await fetchEthereumBalance(
      'polygon',
      baseWallets.metamask.address
    )
    stateDispatch((state) => ({ ...state, metamask: balance }))
  }, [baseWallets.metamask, stateDispatch])

  const refreshNamiBalance = useCallback(async () => {
    if (baseWallets.nami.status !== 'linked') {
      return stateDispatch((state) => ({ ...state, nami: [] }))
    }
    const balance = await nami.getBalance()
    const balanceWithMax = await addMaxQuantity(
      balance,
      'nami',
      baseWallets.nami.address
    )
    const isPending = await fetchIsPending(baseWallets.nami.address)
    const finalBalance = balanceWithMax.map((balance) => ({
      ...balance,
      isPending,
    }))
    stateDispatch((state) => ({ ...state, nami: finalBalance }))
  }, [baseWallets.nami, stateDispatch])

  const refreshEternlBalance = useCallback(async () => {
    if (baseWallets.eternl.status !== 'linked') {
      return stateDispatch((state) => ({ ...state, eternl: [] }))
    }
    const balance = await eternl.getBalance()
    const balanceWithMax = await addMaxQuantity(
      balance,
      'eternl',
      baseWallets.eternl.address
    )
    const isPending = await fetchIsPending(baseWallets.eternl.address)
    const finalBalance = balanceWithMax.map((balance) => ({
      ...balance,
      isPending,
    }))
    stateDispatch((state) => ({ ...state, eternl: finalBalance }))
  }, [baseWallets.eternl, stateDispatch])

  useIntervalPolling(refreshMetamaskBalance)
  useIntervalPolling(refreshNamiBalance)
  useIntervalPolling(refreshEternlBalance)

  return (
    <balanceContext.Provider value={[state, stateDispatch]}>
      {children}
    </balanceContext.Provider>
  )
}

export function useBalances(): State {
  return useContext(balanceContext)[0]
}
