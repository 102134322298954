import { NotificationsContext } from './NotificationsContext'
import {
  AddNotificationType,
  NotificationInterface,
  NotificationType,
} from './interface'
import * as S from './styles'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { addNewNotification } from 'ui/Common/components/Notifications/utils'
import Text from 'ui/Common/components/Text'
import { CancelIcon } from 'ui/Icons/components/CancelIcon'
import { InfoIcon } from 'ui/Icons/components/InfoIcon'
import { SuccessIcon } from 'ui/Icons/components/SuccessIcon'

interface NotificationProps {
  children: ReactNode
}

const NOTIFICATION_TIMEOUT = 5000 // 5s

/** Create random notification ID. */
const createNotificationId = () => Math.random().toString(36).substr(2, 7)

/**
 * Returns icon for each notification type
 */
function getIcon(type: NotificationType) {
  const iconProps = {
    width: 24,
    height: 24,
  }

  switch (type) {
    case NotificationType.Success:
      return <SuccessIcon {...iconProps} />

    case NotificationType.Info:
      return <InfoIcon {...iconProps} />

    case NotificationType.Error:
      return <CancelIcon {...iconProps} />

    default:
      return null
  }
}

/**
 * Renders single notification with dismiss button.
 */
export function Notification({
  onDismissClick,
  notification,
}: {
  notification: NotificationInterface
  onDismissClick: (notificationId: string) => void
}) {
  const timeout = useRef(0)

  const removeNotification = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)

      timeout.current = 0
    }

    onDismissClick(notification.id)
  }, [notification.id, onDismissClick])

  useEffect(() => {
    if (!notification.persist && !timeout.current) {
      // window hints TS that browser implementation of a setTimeout is used
      timeout.current = window.setTimeout(
        removeNotification,
        NOTIFICATION_TIMEOUT
      )
    }
  }, [notification.persist, removeNotification])

  const icon = getIcon(notification.type)

  return (
    <motion.div
      key={notification.id}
      initial={{
        x: 500,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      exit={{
        x: 500,
        opacity: 0,
      }}
    >
      <S.NotificationWrapper>
        {icon && <S.IconWrapper>{icon}</S.IconWrapper>}

        <S.ContentWrapper>
          {notification.title && (
            <Text lh={2} variant="heading.6">
              {notification.title}
            </Text>
          )}

          {notification.content && (
            <Text variant="paragraph.4">{notification.content}</Text>
          )}

          {notification.actions && notification.actions.length > 0 && (
            <S.ActionsWrapper>
              {notification.actions.map((action) => action)}
            </S.ActionsWrapper>
          )}
        </S.ContentWrapper>

        {!notification.disableClose && (
          <S.CloseWrapper onClick={removeNotification}>
            <S.StyledCloseIcon width={12} height={12} />
          </S.CloseWrapper>
        )}
      </S.NotificationWrapper>
    </motion.div>
  )
}

/**
 * Renders Notification context and displays notifications. Should be rendered once, higher in the component tree (with other providers).
 */
export function Notifications({ children }: NotificationProps) {
  const [notifications, setNotifications] = useState<NotificationInterface[]>(
    []
  )

  const handleAddNotification = useCallback(
    (notification: AddNotificationType) => {
      const id = notification.id || createNotificationId()
      const newNotification = { ...notification, id }

      setNotifications((prev) => {
        return addNewNotification(newNotification, prev)
      })

      return id
    },
    []
  )

  const handleRemoveNotification = useCallback((notificationId: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== notificationId))
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        addNotification: handleAddNotification,
        removeNotification: handleRemoveNotification,
      }}
    >
      <S.NotificationsContainer data-testid="matest-common-notificationContainer">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification
              key={n.id}
              notification={n}
              onDismissClick={handleRemoveNotification}
            />
          ))}
        </AnimatePresence>
      </S.NotificationsContainer>

      {children}
    </NotificationsContext.Provider>
  )
}
