import React from 'react'

export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.984 4.484 15.516.016 10 0zM8.333 14.512L3.822 10 5 8.822l3.333 3.333L15 5.488l1.178 1.179-7.845 7.845z"
      fill={props.fill || '#68D04F'}
    />
  </svg>
)
