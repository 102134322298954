import { createEventEmitter, EventEmitter } from 'crypto/lib/util/event/emitter'

export type DebounceAsyncContext<T> = {
  /**
   * Take async function and schedule it for execution while canceling any pending calls before that.
   *
   * This fn returns a promise which is chained to a callback's returned promise. If a
   * callback does not return a promise then an implicite one will be used and which will not be
   * resolved at the same time as a callback which might produce unexpected results.
   */
  run: (callback: () => Promise<T>) => Promise<T>
  /**
   * Cancel any pending call and reset context
   */
  cancel: () => void
  /**
   * Flag that indicates that context is doing something. This includes both delay and time
   * to resolve result promise.
   */
  isProcessing: boolean
  /**
   * Event emitter that emits events any time `isProcessing` property changes.
   *
   * This allows for code outside of `run` promise chain to be notified of changes.
   */
  onProcessingChange: EventEmitter
}

export type DebounceContextOptions = {
  delay?: number
}

/**
 * Creates context object that provides functions for debouncing async calls
 * Any call made while the previous call has not yet finished will cancel previous call
 * and start a new one.
 *
 * Context should not be recreated/destroyed while there are pending calls. If there
 * is a need for that, then the caller should use `cancel` function to clear any
 * pending calls.
 *
 * Options:
 *  - `delay` - how long to wait before the (last) async function is called (default is 0)
 */
export function createDebounceAsyncContext<T>(
  options?: DebounceContextOptions
): DebounceAsyncContext<T> {
  let _lastCancel: (() => void) | undefined
  let _lastTimeout: any

  // update internal value and context object
  function setIsProcessing(value: boolean) {
    if (context.isProcessing !== value) {
      context.isProcessing = value
      context.onProcessingChange.emit(value)
    }
  }

  function cancelFn() {
    _lastCancel?.()
    _lastCancel = undefined

    _lastTimeout && clearTimeout(_lastTimeout)
    _lastTimeout = undefined

    setIsProcessing(false)
  }

  const runFn = (callback: () => Promise<T>) => {
    // clear previous values
    cancelFn()

    setIsProcessing(true)

    const { controlled, cancel, reject, resolve } = controlPromise<T>()

    _lastCancel = cancel

    // schedule new call
    _lastTimeout = setTimeout(() => {
      // execute callback and resolve/reject returned control promise when done
      callback().then(
        (value) => {
          resolve?.(value)

          setIsProcessing(false)
        },
        (err) => {
          reject?.(err)

          setIsProcessing(false)
        }
      )
    }, options?.delay ?? 0)

    return controlled
  }

  // create context object in closure so we can update "isProcessing" property
  const context: DebounceAsyncContext<T> = {
    run: runFn,
    cancel: cancelFn,
    isProcessing: false,
    onProcessingChange: createEventEmitter('processingchange'),
  }

  return context
}

/** Create new control promise and expose it's internal parts to allow external control */
function controlPromise<T>(): {
  controlled: Promise<T>
  cancel: () => void
  resolve?: (value: T) => void
  reject?: (reason?: any) => void
} {
  let resolve: ((value: T) => void) | undefined
  let reject: ((reason?: any) => void) | undefined

  const controllerPromise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  function cancelFn() {
    resolve = undefined
    reject = undefined
  }

  function resolveFn(value: T) {
    resolve?.(value)
  }

  function rejectFn(value: T) {
    reject?.(value)
  }

  return {
    controlled: controllerPromise,
    cancel: cancelFn,
    reject: rejectFn,
    resolve: resolveFn,
  }
}
