import React from 'react'

export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 20c5.514 0 10-4.486 10-10S15.514 0 10 0 0 4.486 0 10s4.486 10 10 10zm-1.37-4.167.537-7.5h1.666l.507 7.5H8.63zM10 3.333a1.666 1.666 0 1 1 0 3.333 1.666 1.666 0 0 1 0-3.333z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)
