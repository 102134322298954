import * as SL from '../serializationLib'
import { cardanoNetworkId } from 'crypto/config'
import { CryptoUserError } from 'crypto/interface'
import { Balance } from 'crypto/lib/balance'

interface NamiGlobal {
  enable(): Promise<NamiApi>
  isEnabled(): Promise<boolean>
}

type NamiEventMap = {
  accountChange(addresses: string[]): void
  networkChange(network: number): void
}

export type NamiEvents = {
  [f in 'on' | 'off']: <K extends keyof NamiEventMap>(
    event: K,
    listener: NamiEventMap[K]
  ) => void
}

interface NamiExperimental extends NamiEvents {
  getCollateral(): Promise<string[]>
}

interface NamiApi {
  experimental: NamiExperimental
  getNetworkId(): Promise<number>
  getUsedAddresses(): Promise<string[]>
  getChangeAddress(): Promise<string>
  getRewardAddress(): Promise<string>
  getBalance(): Promise<string>
  getUtxos(): Promise<string[]>
  signTx(tx: string, partialSign?: boolean): Promise<string>
  signData(addr: string, sigStructure: string): Promise<string>
}

function getGlobal(): NamiGlobal | undefined {
  return (window as any)?.cardano?.nami
}

export async function getApi(): Promise<NamiApi | undefined> {
  const namiGlobal = getGlobal()
  if (!namiGlobal) return
  if (!(await namiGlobal.isEnabled())) return
  return await namiGlobal.enable()
}

async function getAddressFromApi(api: NamiApi): Promise<string> {
  const address = (await api.getUsedAddresses())[0]
  return SL.Address.from_bytes(Buffer.from(address, 'hex')).to_bech32()
}

function checkAddress(address: string) {
  try {
    return SL.Address.from_bech32(address).to_bech32()
  } catch {}
  try {
    return SL.Address.from_bytes(Buffer.from(address, 'hex')).to_bech32()
  } catch {}
  return
}

// Current system is using globals because `disconnect` event is not implemented in nami
let globalLastAddress: string | undefined
let globalOnStatus: ((address: string | undefined) => void) | undefined
let globalOnIncorrectNetwork:
  | ((desiredNetwork: string | undefined) => void)
  | undefined

async function updateAddress(isInitial = false) {
  const namiGlobal = getGlobal()
  // Dont call globalOnStatus when we don't have nami as it assumes that nami is available
  if (!namiGlobal) return

  const api = await getApi()
  const address = api && checkAddress(await getAddressFromApi(api))

  if (address !== globalLastAddress || isInitial) {
    globalLastAddress = address
    globalOnStatus?.(address)
    if (!globalLastAddress) {
      globalOnIncorrectNetwork?.(undefined)
    }
  }
}

function isCorrectNetwork(networkId: number): boolean {
  const isCorrect = networkId === cardanoNetworkId
  globalOnIncorrectNetwork?.(
    isCorrect ? undefined : cardanoNetworkId === 0 ? 'testnet' : 'mainnet'
  )
  return isCorrect
}

export function getAddress(): string {
  if (globalLastAddress) return globalLastAddress
  throw new CryptoUserError('NAMI_NOT_LINKED')
}

export async function init(
  onStatus: (address: string | undefined) => void,
  onIncorrectNetwork: (desiredNetwork: string | undefined) => void
) {
  globalOnStatus = onStatus
  globalOnIncorrectNetwork = onIncorrectNetwork
  await updateAddress(true)
  setInterval(updateAddress, 1000)

  const api = await getApi()
  if (!api) return
  isCorrectNetwork(await api.getNetworkId())
  connectEvents(api)
}

export async function link(): Promise<'ok' | 'rejected' | 'unknown'> {
  const namiGlobal = getGlobal()
  if (!namiGlobal) return 'unknown'
  if (await namiGlobal.isEnabled()) return 'ok'

  let api: NamiApi
  try {
    api = await namiGlobal.enable()
  } catch (error) {
    if ((error as any)?.code === -3) {
      return 'rejected'
    }
    return 'unknown'
  }

  isCorrectNetwork(await api.getNetworkId())
  connectEvents(api)

  return 'ok'
}

function connectEvents(api: NamiApi) {
  api.experimental.on('accountChange', (addresses: string[]) => {
    const address = checkAddress(addresses[0])
    if (globalLastAddress !== address) {
      globalLastAddress = address
      globalOnStatus?.(address)
    }
  })
  api.experimental.on('networkChange', isCorrectNetwork)
}

export function getBalanceFromValue(balanceHex: string) {
  const value = SL.Value.from_bytes(Buffer.from(balanceHex, 'hex'))

  const balances: Balance[] = [
    {
      unit: 'lovelace',
      quantity: value.coin().to_str(),
      maxQuantity: undefined,
      isPending: false,
      chain: 'cardano',
    },
  ]

  const multiAsset = value.multiasset()
  if (!multiAsset) return balances

  const policyIds = multiAsset.keys()
  for (let i = 0; i < policyIds.len(); i++) {
    const policyId = policyIds.get(i)
    const policy = multiAsset.get(policyId)
    if (!policy) continue
    const assetNames = policy.keys()
    for (let j = 0; j < assetNames.len(); j++) {
      const assetName = assetNames.get(j)
      const asset = policy.get(assetName)
      if (!asset) continue

      balances.push({
        unit:
          Buffer.from(policyId.to_bytes()).toString('hex') +
          Buffer.from(assetName.name()).toString('hex'),
        quantity: asset.to_str(),
        maxQuantity: undefined,
        isPending: false,
        chain: 'cardano',
      })
    }
  }

  return balances
}

export async function getBalance(): Promise<Balance[]> {
  const api = await getApi()
  if (!api) throw new CryptoUserError('NAMI_NOT_LINKED')

  const balance = await api.getBalance()

  return getBalanceFromValue(balance)
}

export async function getUtxos() {
  const api = await getApi()
  if (!api) throw new CryptoUserError('NAMI_NOT_LINKED')

  return api.getUtxos()
}

export async function getCollateral() {
  const api = await getApi()
  if (!api) throw new CryptoUserError('NAMI_NOT_LINKED')
  const collateral = await api.experimental.getCollateral()
  return collateral
    .slice(0, 1) // return 1 utxo at most
    .map((coll) =>
      SL.TransactionUnspentOutput.from_bytes(Buffer.from(coll, 'hex'))
    )
}

export async function signTransaction(
  transaction: SL.FixedTransaction,
  partial?: boolean
): Promise<SL.TransactionWitnessSet> {
  const api = await getApi()
  if (!api) throw new CryptoUserError('NAMI_NOT_LINKED')

  let witneses
  try {
    witneses = await api.signTx(
      Buffer.from(transaction.to_bytes()).toString('hex'),
      partial
    )
  } catch (error: any) {
    if (error.code === 2 || error.code === -3) {
      throw new CryptoUserError('NAMI_TRANSACTION_CANCELED')
    } else {
      throw new CryptoUserError('NAMI_UNEXPECTED_ERROR')
    }
  }

  return SL.TransactionWitnessSet.from_bytes(Buffer.from(witneses, 'hex'))
}

export async function signMessage(
  message: string
): Promise<string | undefined> {
  const api = await getApi()
  if (!api) throw new CryptoUserError('NAMI_NOT_LINKED')

  const address = await getAddressFromApi(api)

  return await api.signData(
    address,
    Buffer.from(message, 'utf8').toString('hex')
  )
}
