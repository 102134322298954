import { isAddress } from '@ethersproject/address'
import { generateMnemonic } from 'bip39'
import { pollTime } from 'crypto/config'
import { ChainType } from 'crypto/interface'
import { Address } from 'crypto/lib/cardano/serializationLib'
import { useCallback, useEffect } from 'react'

export function useValidateAddress(
  chain: ChainType
): (address: string) => boolean {
  return useCallback(
    (address) => {
      if (chain === 'cardano') {
        try {
          Address.from_bech32(address)
          return true
        } catch {
          return false
        }
      } else {
        return isAddress(address)
      }
    },
    [chain]
  )
}

export function useGenerateSeedPhrase() {
  return useCallback(() => generateMnemonic().split(' '), [])
}

export function useIntervalPolling(
  callback: () => void,
  time: number = pollTime
) {
  useEffect(() => {
    callback()
    const interval = setInterval(callback, time)
    return () => clearInterval(interval)
  }, [callback, time])
}
