import * as colors from '../colors'
import { container, spacing, flexboxgrid } from '../spacing'
import * as typography from '../typography'
import { DefaultTheme } from 'styled-components'

export const light: DefaultTheme = {
  colors: colors.light,
  flexboxgrid,
  spacing,
  container,
  typography: {
    heading: typography.heading,
    paragraph: typography.paragraph,
    fontWeight: typography.FontWeight,
    fontFamily: typography.FontFamily,
  },
  elements: {
    sidebar: {
      backgroundColor: colors.light.white.white100,
    },
  },
}

export const dark: DefaultTheme = {
  colors: colors.dark,
  flexboxgrid,
  spacing,
  container,
  typography: {
    heading: typography.heading,
    paragraph: typography.paragraph,
    fontWeight: typography.FontWeight,
    fontFamily: typography.FontFamily,
  },
  elements: {
    sidebar: {
      backgroundColor: colors.dark.grey.grey2,
    },
  },
}

interface ElementSpecificTheming {
  backgroundColor?: string
  color?: string
}

declare module 'styled-components' {
  export interface DefaultTheme {
    flexboxgrid: typeof flexboxgrid
    colors: typeof colors.light
    spacing: typeof spacing
    container: typeof container
    typography: {
      heading: typeof typography.heading
      paragraph: typeof typography.paragraph
      fontWeight: typeof typography.FontWeight
      fontFamily: typeof typography.FontFamily
    }
    elements: { sidebar: ElementSpecificTheming }
  }
}
