import { allAssets } from 'crypto/config'
import { AssetType, ChainType, WalletId } from 'crypto/interface'
import * as metamask from 'crypto/lib/ethereum/metamask'
import { useCallback } from 'react'

export function useAddCustomToken() {
  return useCallback(
    async (
      walletId: WalletId,
      chain: ChainType,
      assetId: AssetType
    ): Promise<boolean> => {
      const registrationError = (msg: string) =>
        Error(`${msg}: wallet: ${walletId}, chain: ${chain}, asset: ${assetId}`)

      const config = allAssets.find((a) => a.id === assetId)
      if (config == null) {
        throw registrationError(`Asset config not found`)
      }

      if (walletId === 'metamask') {
        if (chain === 'cardano') throw registrationError('Unexpected chain')

        const chainConfig = config[chain]
        if (chainConfig == null) throw registrationError('Unknown chain config')

        try {
          return metamask.watchAsset(chainConfig)
        } catch (err: unknown) {
          throw registrationError(`Error registering asset ${err}`)
        }
      } else {
        throw registrationError(`Registering token not supported for wallet`)
      }
    },
    []
  )
}
