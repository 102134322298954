import { AddNotificationType } from './interface'
import { createContext, useContext } from 'react'

/**
 * Used under the hood to store notification. Developers should use `useNotification` custom hook to get access to notifications.
 */
export const NotificationsContext = createContext<{
  addNotification: (notification: AddNotificationType) => string
  removeNotification: (id: string) => void
}>({
  addNotification: () => '',
  removeNotification: () => {},
})

/**
 * Custom hook which provides controls for displaying and hiding notifications from the screen.
 */
export function useNotifications() {
  const { addNotification, removeNotification } =
    useContext(NotificationsContext)

  return { addNotification, removeNotification }
}
