import { useUnitPrices } from './unitPrice'
import { useIntervalPolling } from './util'
import { useBaseWallets } from './wallets'
import BigNumber from 'bignumber.js'
import { allAssets } from 'crypto/config'
import {
  AkamonFee,
  AkamonTransfer,
  ChainMap,
  ChainType,
  TransactionBuilderResult,
} from 'crypto/interface'
import {
  AkamonBetaBridgingRequest,
  getAkamonBetaEta,
  listInAkamonBeta,
} from 'crypto/lib/akamon'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import { UnitPrices } from 'crypto/lib/unitPrice'
import { fromQuantity } from 'crypto/lib/util'
import { uniq } from 'lodash'
import compact from 'lodash/compact'
import { useCallback, useEffect, useMemo, useState } from 'react'

function createBridgedItemInAkamonBeta(
  request: AkamonBetaBridgingRequest,
  unitPrices: UnitPrices
): AkamonTransfer {
  const assetConfig = allAssets.find(({ id }) => id === request.token)!
  const fromChain = request.sourceChain
  const toChain = request.destinationChain
  const fromConfig = assetConfig[fromChain]!
  const toConfig = assetConfig[toChain]!
  const assetPrice = unitPrices[assetConfig.id]
  const feeConfig = allAssets.find(
    (asset) => asset[request.sourceChain]?.isCoin
  )!
  const feeChainConfig = feeConfig[request.sourceChain]!
  const feePrice = unitPrices[feeConfig.id]
  const feeAmount = fromQuantity(
    new BigNumber(request.networkFee.contents).plus(
      request.protocolFee.contents
    ),
    feeChainConfig.decimals
  )
  const amount = fromQuantity(request.amount, fromConfig.decimals)

  return {
    from: {
      type: request.token,
      name: fromConfig.name,
      amount,
      unitPrice: assetPrice,
      chain: fromChain,
      hash: request.requestTxId,
    },
    to: {
      type: request.token,
      name: toConfig.name,
      amount,
      unitPrice: assetPrice,
      chain: toChain,
      hash: request.fulfillmentTxId,
    },
    fee: {
      type: feeConfig.id,
      name: feeChainConfig.name,
      amount: feeAmount,
      unitPrice: feePrice,
    },
    startTimestamp: request.submittedAt,
    endTimestamp: request.fulfilledAt,
    status: request.status,
  }
}

export function useAkamonBetaInProcessCount(): number {
  const items = useAkamonBetaTxList()
  return useMemo(() => {
    return (
      items?.filter(({ status }) => status !== 'Done' && status !== 'Failed')
        .length ?? 0
    )
  }, [items])
}

function useAllAddresses() {
  const { meldStorage, nami, metamask, eternl } = useBaseWallets()
  const addresses = useMemo(() => {
    const meldAddresses = Array.from(meldStorage.values()).map(
      ({ baseAddress }) => baseAddress
    )
    const namiAddress = nami.status === 'linked' ? nami.address : undefined
    const eternlAddress =
      eternl.status === 'linked' ? eternl.address : undefined
    const metamaskAddress =
      metamask.status === 'linked' ? metamask.address : undefined

    return compact([
      ...meldAddresses,
      namiAddress,
      eternlAddress,
      metamaskAddress,
    ])
  }, [meldStorage, nami, metamask, eternl])
  return addresses
}

export function useAkamonBetaTxList(): AkamonTransfer[] | undefined {
  const [requests, setRequests] = useState<AkamonBetaBridgingRequest[]>()
  const unitPrices = useUnitPrices()
  const addresses = useAllAddresses()

  const items = useMemo(() => {
    if (!requests) return undefined
    return requests.map((request) =>
      createBridgedItemInAkamonBeta(request, unitPrices)
    )
  }, [requests, unitPrices])

  const updateTransactions = useCallback(async () => {
    if (addresses.length > 0) {
      const eternlAddresses = await eternl.getAllAddresses()
      const addressesWithEternl = uniq([...addresses, ...eternlAddresses])
      listInAkamonBeta(addressesWithEternl).then((requests) =>
        setRequests(requests)
      )
    } else {
      setRequests([])
    }
  }, [addresses])

  useIntervalPolling(updateTransactions, 10000)

  return items
}

export function useAkamonBetaFee() {
  const unitPrices = useUnitPrices()

  const createFee = useCallback(
    (
      chain: ChainType | undefined,
      result: TransactionBuilderResult | undefined
    ): AkamonFee | undefined => {
      if (chain == null || result == null) {
        return
      }

      const name =
        chain === 'cardano' ? 'ADA' : chain === 'polygon' ? 'MATIC' : undefined
      const amount =
        result.fee && result.protocolFee
          ? result.fee.plus(result.protocolFee)
          : undefined
      const unitPrice =
        chain === 'cardano'
          ? unitPrices.ada
          : chain === 'polygon'
          ? unitPrices.matic
          : undefined
      return name && amount ? { name, amount, unitPrice } : undefined
    },
    [unitPrices.ada, unitPrices.matic]
  )

  return createFee
}

export function useAkamonBetaEta(): ChainMap<number> {
  const [eta, setEta] = useState<ChainMap<number>>({})

  useEffect(() => {
    getAkamonBetaEta().then((result) => {
      result && setEta(result)
    })
  }, [])

  return eta
}
