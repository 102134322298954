import '../src/app/styles/globals.css'
import { init } from '@socialgouv/matomo-next'
import env from 'app/lib/env'
import { light } from 'app/styles/themes'
import { initCryptoConfig } from 'crypto/config'
import { CryptoProvider } from 'crypto/hooks'
import * as BackendHTTPClient from 'crypto/lib/backendHttpClient'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import { UIDReset } from 'react-uid'
import semverLt from 'semver/functions/lt'
import semverValid from 'semver/functions/valid'
import { ThemeProvider } from 'styled-components'
import { Notifications } from 'ui/Common/components/Notifications'
import { initUiConfig } from 'ui/Common/config'

initCryptoConfig(env)
initUiConfig(env)

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (env.matomoUrl && env.matomoSiteId) {
      init({ url: env.matomoUrl, siteId: env.matomoSiteId })
    }
  }, [])

  useEffect(() => {
    // ocasionally check for forced upgrades
    const timer = setInterval(() => {
      // FIXME move this to another module, write tests
      if (semverValid(env.meldappVersion)) {
        BackendHTTPClient.get('api/metadata/meldapp/versions', {})
          .then((versions) => {
            // is current version < min required?
            const shouldForceUpgrade = semverLt(
              env.meldappVersion,
              versions.min.web
            )
            if (shouldForceUpgrade) {
              window.location.reload()
            }
          })
          .catch((_e) => {
            console.log('Failed to connect to MELDapp backend!')
          })
      } else {
        console.log(
          'MELDAPP_VERSION (' +
            env.meldappVersion +
            ') not valid semver, ignoring.'
        )
      }
    }, 30000)

    return () => clearInterval(timer)
  }, [])

  return (
    <>
      <Head>
        <title>MELD</title>
        <meta name="description" content="MELD" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <UIDReset>
        <ThemeProvider theme={light}>
          <Notifications>
            <CryptoProvider>
              <Component {...pageProps} />
            </CryptoProvider>
          </Notifications>
        </ThemeProvider>
      </UIDReset>

      <div
        dangerouslySetInnerHTML={{ __html: '<!-- ' + env.commitSHA + ' -->' }}
      ></div>
    </>
  )
}

export default MyApp
