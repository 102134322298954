import { get } from './backendHttpClient'
import BigNumber from 'bignumber.js'
import { allAssets } from 'crypto/config'
import { AssetType } from 'crypto/interface'

export type UnitPrices = Partial<Record<AssetType, BigNumber>>

export async function fetchUnitPrices(): Promise<UnitPrices> {
  const tickers = allAssets.map((config) => config.priceTicker).join(',')

  const prices = await get('api/market/prices', {
    tickers,
    fiat: 'USD',
  })

  const unitPriceEntries = allAssets.map(({ id, priceTicker }) => {
    const data = prices[priceTicker]
    return [id, data ? new BigNumber(data.price) : undefined] as const
  })

  return Object.fromEntries(unitPriceEntries)
}
