import { ChainType, WalletId } from 'crypto/interface'

export let isAkamonEnabled: boolean
export let isEternlEnabled: boolean

export let blockExplorerURLs: Record<ChainType, string>
export let supportedWalletIds: WalletId[]

export function initUiConfig(env: { network: string; environment: string }) {
  const isMainnet = env.network === 'mainnet'

  isAkamonEnabled = false
  isEternlEnabled = true

  blockExplorerURLs = isMainnet
    ? {
        ethereum: 'https://etherscan.io/tx/',
        polygon: 'https://polygonscan.com/tx/',
        cardano: 'https://cardanoscan.io/transaction/',
      }
    : {
        ethereum: 'https://goerli.etherscan.io/tx/',
        polygon: 'https://mumbai.polygonscan.com/tx/',
        cardano: 'https://testnet.cardanoscan.io/transaction/',
      }

  supportedWalletIds = ['nami']
  if (isAkamonEnabled) supportedWalletIds.push('metamask')
  if (isEternlEnabled) supportedWalletIds.push('eternl')
}

// Default environment, if `ui` is used without initializing it this will be default env values
initUiConfig({ network: 'testnet', environment: 'dev' })
