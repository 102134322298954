import { useEffect, useState } from 'react'
import {
  createDebounceAsyncContext,
  DebounceAsyncContext,
  DebounceContextOptions,
} from 'ui/Common/util/async/debounceAsync'

/**
 * Creates context for debouncing async call.
 * Context is created once and never changes during the lifetime of the hook.
 *
 * Hook returns douboncing context and flag if context is processing.
 * Processing flag is available on context itslef but that value is not reactive.
 *
 * See `debounceAsync()` for details.
 */
export function useDebounceAsync<T = any>(
  options?: DebounceContextOptions
): [DebounceAsyncContext<T>, boolean] {
  // context must not be recreated during it's lifetime that's why we used `useState` initial state function
  const [debounceContext] = useState(() =>
    createDebounceAsyncContext<T>(options)
  )
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  // cleanup on unmount
  useEffect(() => {
    debounceContext.onProcessingChange.on(setIsProcessing)

    return () => debounceContext.cancel()
  }, [debounceContext])

  return [debounceContext, isProcessing]
}
