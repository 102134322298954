import {
  AnyAssetConfig,
  NativeCardanoAssetConfig,
  NativePolygonAssetConfig,
} from 'crypto/interface'

const meld: NativeCardanoAssetConfig = {
  priceTicker: 'MELD',
  id: 'meld',
  nativeChain: 'cardano',
  cardano: {
    isCoin: false,
    policyId: '4ebcb13f01e735976486b3c39072eed6d9f362e916fb04fe2c37f951',
    assetName: '744d454c44',
    ticker: 'MELD',
    name: 'MELD',
    isStakeable: true,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'mMELD',
    name: 'mMELD',
    address: '0x8c329f4f153083aeb133d1407a61967a5d7f1f19',
    decimals: 6,
    image: 'https://d13tn3ghacljy8.cloudfront.net/mMELD.png',
  },
  ethereum: undefined,
}

const ada: NativeCardanoAssetConfig = {
  priceTicker: 'ADA',
  id: 'ada',
  nativeChain: 'cardano',
  cardano: {
    isCoin: true,
    policyId: undefined,
    assetName: undefined,
    name: 'ADA',
    ticker: 'ADA',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'mADA',
    name: 'mADA',
    address: '0xcbdfd52b3314057990678d07dd6b04ff511c0b0c',
    decimals: 6,
    image: 'https://meld-public.s3.eu-west-1.amazonaws.com/mADA.png',
  },
  ethereum: undefined,
}

const matic: NativePolygonAssetConfig = {
  priceTicker: 'MATIC',
  id: 'matic',
  nativeChain: 'polygon',
  cardano: {
    isCoin: false,
    policyId: '53c331eac2651d9d4a6e86a4aadfd31364a1de8ce1fd02b669a84e41',
    assetName: '6d4d41544943',
    ticker: 'mMATIC',
    name: 'mMATIC',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: true,
    ticker: 'MATIC',
    name: 'MATIC',
    address: undefined,
    decimals: 18,
    image: undefined,
  },
  ethereum: undefined,
}

// it's Ethereum really
const usdt: NativePolygonAssetConfig = {
  priceTicker: 'USDT',
  id: 'usdt',
  nativeChain: 'polygon',
  cardano: {
    isCoin: false,
    policyId: '53c331eac2651d9d4a6e86a4aadfd31364a1de8ce1fd02b669a84e41',
    assetName: '6d55534454',
    ticker: 'mUSDT',
    name: 'mUSDT',
    isStakeable: false,
    decimals: 6,
    image: undefined,
  },
  polygon: {
    isCoin: false,
    ticker: 'USDT',
    name: 'USDT',
    address: '0x3813e82e6f7098b9583fc0f33a962d02018b6803',
    decimals: 6,
    image: 'https://d13tn3ghacljy8.cloudfront.net/USDT.png',
  },
  ethereum: undefined,
}

const testnet: AnyAssetConfig[] = [
  meld,
  ada,
  matic,
  //  usdt
]
export default testnet
