import isArray from 'lodash/isArray'

const LS_KEY = 'meldCardanoStorage'

export type WalletStorage = {
  name: string
  timestamp: number
  baseAddress: string
  enterpriseAddress: string
  rewardAddress: string
  encryptedRootKey: string
}

function getAndValidateStoredAddresses(): (WalletStorage | undefined)[] {
  const cardanoStorage = window.localStorage.getItem(LS_KEY)
  if (!cardanoStorage) return []

  const result = JSON.parse(cardanoStorage)
  if (!isArray(result)) {
    window.localStorage.removeItem(LS_KEY)
    return []
  }

  return result
}

export function getNextWalletId(): number {
  return getAndValidateStoredAddresses().length
}

export function getStoredAddresses(): Map<number, WalletStorage> {
  const addresses = new Map<number, WalletStorage>()
  getAndValidateStoredAddresses().forEach((address, id) => {
    if (address) {
      addresses.set(id, address)
    }
  })
  return addresses
}

export function findStoredAddresses(
  walletId: number
): WalletStorage | undefined {
  return getAndValidateStoredAddresses()[walletId]
}

export function removeStoredAddresses(walletId: number): boolean {
  const storage = getAndValidateStoredAddresses()

  if (!storage[walletId]) return false

  storage[walletId] = undefined
  window.localStorage.setItem(LS_KEY, JSON.stringify(storage))
  return true
}

export function storeWalletAddresses(walletAddresses: WalletStorage): number {
  const storage = getAndValidateStoredAddresses()

  const id = storage.length
  storage.push(walletAddresses)

  window.localStorage.setItem(LS_KEY, JSON.stringify(storage))

  return id
}
