export type StorageKey = 'metamaskStorage' | 'namiStorage' | 'eternlStorage'

type Storage = { timestamp: number }

function getStorage(key: StorageKey): Storage | undefined {
  const storageString = window.localStorage.getItem(key)
  const storage = storageString ? JSON.parse(storageString) : undefined
  if (
    storage &&
    typeof storage === 'object' &&
    typeof storage.timestamp === 'number'
  )
    return storage
  return
}

export function updateStorage(key: StorageKey): number {
  const storage = getStorage(key)
  if (storage) return storage.timestamp

  const timestamp = Date.now()
  window.localStorage[key] = JSON.stringify({ timestamp })
  return timestamp
}

export function clearStorage(key: StorageKey) {
  window.localStorage.removeItem(key)
}

export * from 'crypto/lib/cardano/storage'
