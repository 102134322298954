type CallbackFunction<V> = (arg: V) => void
type RemoveFunction = () => void

/** Simple event emitter object. */
export type EventEmitter<V = any> = {
  /** Subscribe listener fn to event */
  on(listener: CallbackFunction<V>): RemoveFunction
  /** Remove listener fn from event */
  off(listener: CallbackFunction<V>): void
  /** Emit new event `value` to listeners */
  emit(value: V): void
}

/** Create and return a new instance of `EventEmitter`. */
export function createEventEmitter<V>(event: string): EventEmitter<V> {
  // map that holds subscriptions for each event type
  const eventSubscriptions: Record<string, CallbackFunction<V>[]> = {}

  return {
    on(listener: CallbackFunction<V>) {
      eventSubscriptions[event] = eventSubscriptions[event] ?? []

      eventSubscriptions[event].push(listener)

      return () => {
        this.off(listener)
      }
    },

    off(listener: CallbackFunction<V>) {
      eventSubscriptions[event] = eventSubscriptions[event].filter(
        (ln) => ln !== listener
      )
    },

    emit(value: V) {
      const listeners = eventSubscriptions[event] ?? []
      listeners.forEach((ln) => {
        try {
          ln(value)
        } catch (err) {
          console.error(`Error calling listener on "${event}" event emitter`)
        }
      })
    },
  }
}
