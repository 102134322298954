import { ColorKey } from 'app/styles/colors'
import {
  FontVariantKey,
  FontWeight,
  getFontFromKey,
} from 'app/styles/typography'
import get from 'lodash/get'
import React, { PropsWithChildren } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import { getStylesFromProps, ISizable } from 'ui/Common/util/Sizable'

/**
 * Text public API
 */
export interface TextProps extends ISizable {
  /**
   * Text align
   *
   * @default 'left'
   */
  align?: 'left' | 'right' | 'center'
  /**
   * DOM element component is render as
   *
   * @default 'span'
   */
  as?: 'span' | 'div' | 'li' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  /**
   * Display text as a block instead of inline element.
   */
  block?: boolean
  /**
   * Render "..." if text content does not fit in the container.
   * NOTE: width must be set on the element.
   */
  ellipsis?: boolean
  /**
   * Text color.
   */
  color?: ColorKey | string
  /**
   * Font family.
   */
  // fontFamily?: FontFamily
  /**
   * Font size.
   */
  fontSize?: number
  /**
   * Font weight.
   */
  fontWeight?: FontWeight
  /**
   * Line height property
   */
  lh?: number
  /**
   * Variant of predefined text styles
   */
  variant?: FontVariantKey
  /**
   * Override "styles" props of the element.
   */
  style?: React.CSSProperties
  /**
   * Apply ellipsis after a given number of lines
   */
  // lineClamp?: number
  /** Title */
  title?: string
}

/**
 * A subset of Text props relevant for innwe SC text component.
 */
type InnerTextProps = Pick<
  TextProps,
  | 'align'
  | 'block'
  | 'color'
  | 'ellipsis'
  | 'fontSize'
  | 'fontWeight'
  | 'lh'
  | 'variant'
> & {
  $color: ColorKey | CSSProperties['color']
}

/**
 * Inner SC wrapper for Text component.
 */
const _Text = styled.span<InnerTextProps>`
  ${(p) => p.block && `display: block`};
  ${(p) => p.align && `text-align: ${p.align}`};
  ${(p) => p.variant && getFontFromKey(p.variant)};

  ${(p) => p.$color && `color: ${get(p.theme.colors, p.$color, p.$color)};`}

  ${(p) => p.fontSize && `font-size: ${p.fontSize}px;`}
  ${(p) => p.fontWeight && `font-weight: ${p.fontWeight};`}
  ${(p) => p.lh && `line-height: ${p.lh};`}


  ${(p) =>
    p.ellipsis &&
    css`
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`

/**
 * Generic Text component.
 */
const Text: React.FC<PropsWithChildren<TextProps>> = ({ color, ...props }) => {
  const sizingStyles = getStylesFromProps(props)

  const style: React.CSSProperties = {
    ...props.style,
    ...sizingStyles,
  }

  return <_Text {...props} $color={color} style={style} />
}

// Text defaults
Text.defaultProps = {
  as: 'span',
}

Text.displayName = 'MELD (Text)'

export default Text
