let serializationLib = {}

function loadSerializationLib() {
  if (!('Address' in serializationLib)) {
    return import('@emurgo/cardano-serialization-lib-browser').then((lib) => {
      return lib.default.then((lib) => {
        Object.assign(serializationLib, lib)
      })
    })
  }
  return Promise.resolve(serializationLib)
}

serializationLib.loadSerializationLib = loadSerializationLib

module.exports = serializationLib
