import { estimatedWeiFee } from './metamask'
import BigNumber from 'bignumber.js'
import { get, SortOrderFilterParams } from 'crypto/lib/backendHttpClient'
import { Balance } from 'crypto/lib/balance'

export type EthereumBalance = {
  quantity: string
  unit: string
}

export type EthereumTransaction = {
  fee: string
  hash: string
  timestamp: string
  direction: 'IN' | 'OUT'
  balances: {
    quantity: string
    unit: string
  }[]
  action: 'TRANSFER' | 'EXIT' | 'DEPOSIT FOR' | 'DEPOSIT ETH FOR' | 'APPROVE'
  isError: string
  quantity: string
  unit: string
}

export async function fetchBalance(
  chain: 'ethereum' | 'polygon',
  address: string
): Promise<Balance[]> {
  const balances = await get(`api/${chain}/balance`, { address })
  const fee = await estimatedWeiFee()
  return balances.map((balance: EthereumBalance): Balance => {
    const maxQuantity =
      balance.unit === 'wei'
        ? new BigNumber(balance.quantity).minus(fee).toString()
        : balance.quantity
    return {
      ...balance,
      maxQuantity,
      isPending: false,
      chain,
    }
  })
}

export async function fetchTransactions(
  chain: 'ethereum' | 'polygon',
  address: string,
  params: SortOrderFilterParams
): Promise<EthereumTransaction[]> {
  return await get(`api/${chain}/transactions`, { address, ...params })
}

export async function fetchTransactionsByUnit(
  chain: 'ethereum' | 'polygon',
  address: string,
  unit: string,
  params: SortOrderFilterParams
): Promise<EthereumTransaction[]> {
  return await get(`api/${chain}/transactions`, { address, unit, ...params })
}
