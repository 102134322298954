import get from 'lodash/get'

export enum FontWeight {
  Regular = 400,
  Bold = 700,
}

export enum FontFamily {
  CircularXX = "'CircularXX'",
}

type FontStyleRecord = Record<
  number,
  { size: number; lineHeight: number; weight: FontWeight }
>

export type FontVariantKey =
  | 'heading.1'
  | 'heading.2'
  | 'heading.3'
  | 'heading.4'
  | 'heading.5'
  | 'heading.6'
  | 'heading.7'
  | 'heading.8'
  | 'heading.9'
  | 'paragraph.1'
  | 'paragraph.2'
  | 'paragraph.3'
  | 'paragraph.4'
  | 'paragraph.5'

export const heading: FontStyleRecord = {
  1: { size: 36, lineHeight: 48, weight: FontWeight.Bold },
  2: { size: 24, lineHeight: 34, weight: FontWeight.Bold },
  3: { size: 22, lineHeight: 32, weight: FontWeight.Bold },
  4: { size: 20, lineHeight: 30, weight: FontWeight.Bold },
  5: { size: 18, lineHeight: 28, weight: FontWeight.Bold },
  6: { size: 16, lineHeight: 26, weight: FontWeight.Bold },
  7: { size: 14, lineHeight: 24, weight: FontWeight.Bold },
  8: { size: 12, lineHeight: 16, weight: FontWeight.Bold },
  9: { size: 10, lineHeight: 14, weight: FontWeight.Bold },
}

export const paragraph: FontStyleRecord = {
  1: { size: 18, lineHeight: 28, weight: FontWeight.Regular },
  2: { size: 16, lineHeight: 26, weight: FontWeight.Regular },
  3: { size: 14, lineHeight: 24, weight: FontWeight.Regular },
  4: { size: 12, lineHeight: 16, weight: FontWeight.Regular },
  5: { size: 10, lineHeight: 16, weight: FontWeight.Regular },
}

export const getFontFromKey = (key: FontVariantKey) => {
  const record = get({ heading, paragraph }, key)
  if (!record) return ``
  return `font: ${record.weight} ${record.size}px/${record.lineHeight}px ${FontFamily.CircularXX}`
}
