import type BigNumber from 'bignumber.js'

export type ChainType = 'cardano' | 'ethereum' | 'polygon'
export type AssetType = 'ada' | 'meld' | 'matic' | 'eth' | 'usdt'

export type ChainMap<T> = Partial<Record<ChainType, T>>

export interface ChainAssetConfig {
  isCoin: boolean
  name: string
  ticker: string
  decimals: number
  image: string | undefined
}

interface CardanoAssetConfigI extends ChainAssetConfig {
  isStakeable: boolean
}

export interface CardanoCoin extends CardanoAssetConfigI {
  isCoin: true
  policyId: undefined
  assetName: undefined
  isStakeable: boolean
}

export interface CardanoToken extends CardanoAssetConfigI {
  isCoin: false
  policyId: string
  assetName: string
  isStakeable: boolean
}

export type CardanoAssetConfig = CardanoCoin | CardanoToken

export interface PolygonCoin extends ChainAssetConfig {
  isCoin: true
  address: undefined
}

export interface PolygonToken extends ChainAssetConfig {
  isCoin: false
  address: string
}

export type PolygonAssetConfig = PolygonCoin | PolygonToken

export interface EthereumCoin extends ChainAssetConfig {
  isCoin: true
  address: undefined
}

export interface EthereumToken extends ChainAssetConfig {
  isCoin: false
  address: string
}

export type EthereumAssetConfig = EthereumCoin | EthereumToken

export interface AssetConfig<
  NativeChain extends ChainType,
  Cardano extends CardanoAssetConfig | undefined,
  Polygon extends PolygonAssetConfig | undefined,
  Ethereum extends EthereumAssetConfig | undefined
> {
  priceTicker: string
  nativeChain: NativeChain
  id: AssetType
  cardano: Cardano
  polygon: Polygon
  ethereum: Ethereum
}

export type AnyAssetConfig = AssetConfig<
  ChainType,
  CardanoAssetConfig | undefined,
  PolygonAssetConfig | undefined,
  EthereumAssetConfig | undefined
>

export type AnyCardanoAssetConfig = AssetConfig<
  ChainType,
  CardanoAssetConfig,
  PolygonAssetConfig | undefined,
  EthereumAssetConfig | undefined
>

export type NativeCardanoAssetConfig = AssetConfig<
  'cardano',
  CardanoAssetConfig,
  PolygonToken | undefined,
  EthereumToken | undefined
>

export type AnyPolygonAssetConfig = AssetConfig<
  ChainType,
  CardanoAssetConfig | undefined,
  PolygonAssetConfig,
  EthereumAssetConfig | undefined
>

export type NativePolygonAssetConfig = AssetConfig<
  'polygon',
  CardanoToken | undefined,
  PolygonAssetConfig,
  EthereumToken | undefined
>

export type AnyEthereumAssetConfig = AssetConfig<
  ChainType,
  CardanoAssetConfig | undefined,
  PolygonAssetConfig | undefined,
  EthereumAssetConfig
>

export type NativeEthereumAssetConfig = AssetConfig<
  'ethereum',
  CardanoToken | undefined,
  PolygonToken | undefined,
  EthereumAssetConfig
>

export interface WalletConfigI<Ch extends ChainType> {
  blockchains: {
    [C in Ch]: {
      address: string | undefined
      assets: (C extends 'cardano'
        ? AnyCardanoAssetConfig
        : C extends 'polygon'
        ? AnyPolygonAssetConfig
        : AnyEthereumAssetConfig)[]
    }
  }
}

export function hasEthereum(a: AnyAssetConfig): a is AnyEthereumAssetConfig {
  return !!a.ethereum
}
export function hasPolygon(a: AnyAssetConfig): a is AnyPolygonAssetConfig {
  return !!a.polygon
}
export function hasCardano(a: AnyAssetConfig): a is AnyCardanoAssetConfig {
  return !!a.cardano
}

export type Vesting = {
  totalAmount: BigNumber
  pendingAmount: BigNumber
  stakedAmount: BigNumber
}

export type VestingType = 'TeamVesting' | 'PrivateSaleVesting'

export interface Asset {
  id: AssetType
  isCoin: boolean
  name: string
  unitBalance: BigNumber
  maxAmount: BigNumber | undefined
  fiatBalance: BigNumber | undefined
  unitPrice: BigNumber | undefined
  change: number | undefined
  decimals: number
  ticker: string
  hasGraph: boolean
  isPending: boolean
  isStakeable: boolean
  isBridgableToCardano: boolean
  isBridgableToPolygon: boolean
  isBridgableToEthereum: boolean
  chain: ChainType
  // TODO: revisit, `hasGraph` should be removed if we use this prop
  fiatBalanceHistory?: number[]
}

export type WalletId = 'metamask' | 'nami' | 'eternl' | number

export interface Wallet {
  id: WalletId
  name: string
  timestamp: number
  fiatBalance: BigNumber
  isPending: boolean
  addresses: ChainMap<string>
  assets: Asset[]
}

export type TransactionLog = {
  chain: ChainType
  hash: string
  timestamp: number
  type: 'Send' | 'Receive' | 'Other'
  success: boolean
  balance: BigNumber | undefined
  amount: BigNumber | undefined
  priceTicker: string | undefined
  assetIds: AssetType[]
}

export type FixedStakingData = {
  totalAmountStaked: number
  position:
    | {
        rewardsAmount: BigNumber
        stakedAmount: BigNumber
        closesAt: Date
        isPending: boolean
        isStakingEnded: boolean
        isClaiming: boolean
        nft: undefined
        ref: string
        walletPkh: string
      }
    | undefined
}

export type VestingData = {
  totalAmount: BigNumber
  pendingAmount: BigNumber
  stakedAmount: BigNumber
  withdrawingAmount: BigNumber | undefined
  outputJson: string
  datumJson: string
  walletPkh: string
  ref: string
}
