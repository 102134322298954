import BigNumber from 'bignumber.js'

export function fromQuantity(
  quantity: BigNumber.Value,
  decimals: BigNumber.Value
): BigNumber {
  return new BigNumber(quantity).div(new BigNumber(10).pow(decimals))
}

export function toQuantity(
  amount: BigNumber.Value,
  decimals: BigNumber.Value
): BigNumber {
  return new BigNumber(amount)
    .times(new BigNumber(10).pow(decimals))
    .integerValue(BigNumber.ROUND_DOWN)
}
