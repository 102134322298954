import { useIntervalPolling } from './util'
import { cardanoAssets } from 'crypto/config'
import { VestingData, Wallet } from 'crypto/interface'
import { fetchVestingPosition } from 'crypto/lib/cardano/connector'
import { fromQuantity } from 'crypto/lib/util'
import { useCallback, useMemo, useState } from 'react'

export function useVestingData(
  wallet: Wallet | undefined
): VestingData | undefined {
  return useVestingDataByAddress(wallet?.addresses.cardano)
}

export function useVestingDataByAddress(
  address: string | undefined
): VestingData | undefined {
  const decimals = useMemo(
    () => cardanoAssets.find(({ id }) => id === 'meld')?.cardano.decimals ?? 0,
    []
  )
  const [data, setData] = useState<VestingData | undefined>()

  const fetchData = useCallback(async (): Promise<VestingData | undefined> => {
    const p = address && (await fetchVestingPosition([address]))

    if (!p) {
      return
    }
    return {
      totalAmount: fromQuantity(p.total_amount, decimals),
      pendingAmount: fromQuantity(p.unvested_amount, decimals),
      stakedAmount: fromQuantity(p.withdrawable_amount, decimals),
      withdrawingAmount: p.pending_withdrawal
        ? fromQuantity(p.pending_withdrawal.withdraw_amount, decimals)
        : undefined,
      outputJson: p.output_json,
      datumJson: p.datum_json,
      walletPkh: p.wallet_pkh,
      ref: p.original_ref, // FIXME should this be called original_ref then?
    }
  }, [address, decimals])

  const updateData = useCallback(async () => {
    setData(await fetchData())
  }, [fetchData])

  useIntervalPolling(updateData)

  return data
}
