import * as Sentry from '@sentry/browser'
import BigNumber from 'bignumber.js'
import { adaDecimals, cardanoAssets, meldBackendUrl } from 'crypto/config'
import {
  CryptoUserError,
  VestingData,
  VestingType,
  Wallet,
} from 'crypto/interface'
import { fetchLockedStakingPosition } from 'crypto/lib/cardano/connector'
import * as cardano from 'crypto/lib/cardano/transaction'
import { Transaction } from 'crypto/lib/transaction'
import { fromQuantity, toQuantity } from 'crypto/lib/util'
import JSONbig from 'json-bigint'
import { useMemo } from 'react'
import { STAKE_POOL_12_APY, STAKE_POOL_6_APY } from 'ui/Staking/utils'
import { openLockedStakingPosition, submitTransaction } from './connector'
import * as SL from './serializationLib'
import * as eternl from './wallet/eternl'
import * as nami from './wallet/nami'

const meldAsset = cardanoAssets.find(({ id }) => id === 'meld')!
const meldDecimals = meldAsset.cardano.decimals

export type OpenVestingRecord = {
  address: string
  total: number
  withdrawn: number
  epochs: number
  start: Date
}

export class MeldApi {
  private wallet: typeof nami | typeof eternl
  private walletAddress: string

  public constructor(walletInterface: Wallet, walletAddress?: string) {
    const walletId = walletInterface.id
    if (walletId === 'nami') this.wallet = nami
    else if (walletId === 'eternl') this.wallet = eternl
    else throw new Error(`Unsupported wallet: ${walletId}`)

    walletAddress = walletAddress ?? walletInterface.addresses.cardano
    if (!walletAddress) throw new Error('Unknown wallet address')
    this.walletAddress = walletAddress
  }

  public async createLockedStakeTransaction(
    period: 6 | 12,
    amount: BigNumber
  ): Promise<Transaction> {
    const quantity = toQuantity(amount, meldDecimals)
    const utxoInfo = await this.getUtxoInfo()
    const unsigned = await this.makeTransaction('locked-staking/stake', {
      stakeAmount: quantity,
      utxoInfo,
    })
    const fee = fromQuantity(unsigned.body().fee().to_str(), adaDecimals)
    return {
      amount,
      // min ada is always 2
      fee: fee.plus(2),
      submit: async () => {
        const signed = await this.signTransaction(unsigned)
        const txHex = Buffer.from(signed.to_bytes()).toString('hex')
        return await openLockedStakingPosition(
          this.walletAddress,
          quantity,
          period,
          txHex
        )
      },
    }
  }

  public async createLockedClaimTransaction(
    period: 6 | 12,
    address: string
  ): Promise<Transaction> {
    const utxoInfo = await this.getUtxoInfo()
    const position = await fetchLockedStakingPosition(address, period)
    if (!position) throw new Error(`Position doesn't exist for ${address}`)

    const tx = await this.makeTransaction('locked-staking/unstake', {
      lockedStakingRef: position.ref,
      utxoInfo,
    })

    const fee = fromQuantity(tx.body().fee().to_str(), adaDecimals)

    const factor =
      position.period === 12 ? STAKE_POOL_12_APY : STAKE_POOL_6_APY.div(2)
    const amountQuantity = factor
      .times(position.staked_amount)
      .plus(position.staked_amount)
    const amount = fromQuantity(amountQuantity, meldDecimals)

    return {
      amount,
      fee,
      submit: async () =>
        await this.submitTransaction(await this.signTransaction(tx)),
    }
  }

  public async createTreasuryLockedStaking(
    sixMonthsAmount: BigNumber,
    normalAmount: BigNumber,
    sixMonths01012023Amount : BigNumber
  ) {
    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('locked-staking-treasury/create', {
      sixMonthsOnlyTreasury: sixMonthsAmount.isNaN() ? [] : [sixMonthsAmount],
      normalTreasury: normalAmount.isNaN() ? [] : [normalAmount],
      sixMonthsOnlyTreasuryDeadline01012023: sixMonths01012023Amount.isNaN() ? [] : [sixMonths01012023Amount],
      utxoInfo,
    })
    return await this.submitTransaction(await this.signTransaction(tx))
  }

  public async createWithdrawVestingTransaction(
    vestingData: VestingData,
    amount: BigNumber
  ): Promise<Transaction> {
    const withdrawAmount = toQuantity(amount, meldDecimals)
    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('vesting/withdraw', {
      utxoInfo,
      vestingRef: vestingData.ref,
      withdrawAmount,
    })

    const fee = fromQuantity(tx.body().fee().to_str(), adaDecimals)

    return {
      amount,
      fee,
      submit: async () =>
        await this.submitTransaction(await this.signTransaction(tx)),
    }
  }

  public async openVestingPositions(
    vestingType: VestingType,
    records: OpenVestingRecord[]
  ) {
    const knownPkhs = new Map()
    const openings = await Promise.all(
      records.map(
        async ({ address, total, withdrawn, epochs, start }, index) => {
          const assert = (predicate: any, message: string) => {
            if (!predicate) throw new Error(`Record #${index + 1}: ${message}`)
          }
          assert(address, 'Address is required')
          assert(total > 0, 'Total amount must be positive')
          assert(withdrawn >= 0, 'Withdrawn amount must be non-negative')
          assert(
            withdrawn <= total,
            'Withdrawn amount must be smaller than total amount'
          )
          assert(epochs > 0, 'Epochs must be positive')
          assert(
            !(await this.getVestingPosition(address)),
            `Position already exists for address ${address}`
          )

          const pubKeyHash = getPaymentPubKeyHash(address)
          assert(pubKeyHash, `Not a valid address: ${address}`)
          const mightDupIndex = knownPkhs.get(pubKeyHash)
          assert(
            mightDupIndex === undefined,
            `Duplicated address with Record #${mightDupIndex + 1}: ${address}`
          )
          knownPkhs.set(pubKeyHash, index)

          const amountPerEpoch = toQuantity(total, meldDecimals)
            .div(epochs)
            .integerValue(BigNumber.ROUND_UP)

          return {
            dVestingPKH: { getPubKeyHash: pubKeyHash! },
            dAmountPerEpoch: amountPerEpoch,
            dWithdrawnAmount: toQuantity(withdrawn, meldDecimals),
            dStartTime: start.getTime(),
            dTotalEpochs: epochs,
          }
        }
      )
    )

    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('vesting/update', {
      vestingType,
      vestingCloseOriginRef: [],
      vestingCreatePosition: openings,
      utxoInfo,
    })
    return await this.submitTransaction(await this.signTransaction(tx))
  }

  public async closeVestingPositions(
    vestingType: VestingType,
    addresses: string[]
  ) {
    const fetchedPositions = await Promise.all(
      addresses.map((address) => this.getVestingPosition(address))
    )
    type PositionT = typeof fetchedPositions[number]
    const positions = Array.from(
      new Set(
        fetchedPositions.filter((p: PositionT): p is NonNullable<PositionT> =>
          Boolean(p)
        )
      )
    )

    if (!positions.length) throw new Error('No positions to close')
    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('vesting/update', {
      vestingType,
      vestingCloseOriginRef: positions.map((p) => p.original_ref),
      vestingCreatePosition: [],
      utxoInfo,
    })
    return await this.submitTransaction(await this.signTransaction(tx))
  }

  public async countMigrateVestingPositions(): Promise<number> {
    return this.callApi(
      'get',
      'vesting/migrate-count',
      {},
      (data) => data?.count
    )
  }

  public async migrateVestingPositions(
    batchSize: number,
    addresses: string[] | null
  ) {
    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('vesting/migrate', {
      utxoInfo,
      batchSize,
      addresses,
    })
    return await this.submitTransaction(await this.signTransaction(tx))
  }

  public async mergeVestingPositions(
    vestingType: VestingType,
    from: string,
    into: string
  ) {
    // TODO: Instead of passing `vestingType` in, we can update meldapp-backend to return the vesting type for a position instead. But I want to keep the change minimal for now.
    const fromPos = await this.getVestingPosition(from)
    if (!fromPos) throw new Error(`Position doesn't exist for ${from}`)
    console.log('fromPos', fromPos)
    const openings: unknown[] = []
    if (into !== '') {
      const intoPos = await this.getVestingPosition(into)
      console.log('intoPos', intoPos)
      const data = intoPos
        ? {
            pkh: intoPos.datum.dVestingPKH.getPubKeyHash,
            amountE: new BigNumber(intoPos.datum.dAmountPerEpoch),
            withdrawn: new BigNumber(intoPos.datum.dWithdrawnAmount),
            start: intoPos.datum.dStartTime,
            epochs: intoPos.datum.dTotalEpochs,
          }
        : {
            pkh: ((addr) => {
              const walletPkh = getPaymentPubKeyHash(addr)
              if (!walletPkh) throw new Error(`Not a valid address: ${addr}`)
              return walletPkh
            })(into),
            amountE: 0,
            withdrawn: 0,
            start: fromPos.datum.dStartTime,
            epochs: fromPos.datum.dTotalEpochs,
          }
      const totalAmountE = new BigNumber(fromPos.datum.dAmountPerEpoch).plus(
        data.amountE
      )
      const totalWithdrawn = new BigNumber(fromPos.datum.dWithdrawnAmount).plus(
        data.withdrawn
      )
      console.log([
        totalAmountE.toString(),
        totalWithdrawn.toString(),
        data.pkh,
      ])
      openings.push({
        dVestingPKH: { getPubKeyHash: data.pkh },
        dAmountPerEpoch: totalAmountE,
        dWithdrawnAmount: totalWithdrawn,
        dStartTime: data.start,
        dTotalEpochs: data.epochs,
      })
    }

    const utxoInfo = await this.getUtxoInfo()
    const tx = await this.makeTransaction('vesting/update', {
      vestingType,
      vestingCloseOriginRef: [fromPos.original_ref],
      vestingCreatePosition: openings,
      utxoInfo,
    })
    return await this.submitTransaction(await this.signTransaction(tx))
  }

  private async getVestingPosition(address: string) {
    const res = await fetch(
      `${meldBackendUrl}/api/vesting/position?address=${address}`
    )
    const d = await res.json()
    if (d.ok !== true) throw new Error('Backend not ok!')
    const data = d.data
    if (!data) return null
    const datum = JSONbig.parse(data.datum_json)
    return { data, datum, original_ref: data.original_ref }
  }

  private async callApi<T>(
    method: string,
    path: string,
    body: any,
    handler: (data: any) => T
  ): Promise<T> {
    const walletAddress = this.walletAddress
    try {
      const response = await fetch(`${meldBackendUrl}api/proxy/meld_api`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSONbig.stringify({ method, path, body, walletAddress }),
      })
      const resultJson = await response.json()
      const success = response?.ok && resultJson.ok
      if (!success) throw new Error(resultJson.data?.errMsg)
      return handler(resultJson?.data)
    } catch (e) {
      Sentry.captureException(e, {
        tags: { walletAddress },
        extra: { body: JSON.stringify(body) },
      })
      throw e
    }
  }

  private async makeTransaction(
    path: string,
    body: any
  ): Promise<SL.FixedTransaction> {
    return this.callApi('post', path, body, (data) =>
      SL.FixedTransaction.from_bytes(Buffer.from(data?.tx.cborHex, 'hex'))
    )
  }

  private async signTransaction(tx: SL.FixedTransaction): Promise<SL.FixedTransaction> {
    const signVkeys = (await this.wallet.signTransaction(tx, true)).vkeys()
    if (signVkeys == null) throw Error('Empty Vkeys')
    for (let i = 0, n = signVkeys.len(); i < n; i++) {
      tx.add_vkey_witness(signVkeys.get(i))
    }
    return tx
  }

  private async getUtxoInfo() {
    const utxos = cardano.toUtxos(await this.wallet.getUtxos())
    // TODO: get collateral of eternl wallet.
    const collaterals = await this.wallet.getCollateral()
    if (!collaterals.length)
      throw new CryptoUserError('INSUFFICIENT_COLLATERAL')
    return {
      changeAddress: this.walletAddress,
      paymentInputs: cardano.formatTransactionInputs(utxos),
      collateralInputs: cardano.formatTransactionInputs(collaterals),
    }
  }

  private async submitTransaction(transaction: SL.FixedTransaction) {
    return await submitTransaction(this.walletAddress, transaction)
  }
}

export function useMeldApi(walletInterface: Wallet, walletAddress?: string) {
  return useMemo(
    () => new MeldApi(walletInterface, walletAddress),
    [walletInterface, walletAddress]
  )
}

export function getPaymentPubKeyHash(addrBech32: string): string | undefined {
  const address = SL.Address.from_bech32(addrBech32)
  const anyAddress =
    SL.BaseAddress.from_address(address) ??
    SL.EnterpriseAddress.from_address(address)
  const bytes = anyAddress?.payment_cred().to_keyhash()?.to_bytes()
  return bytes ? Buffer.from(bytes).toString('hex') : undefined
}
