import { useSelectedWalletId } from './selectedWallet'
import { LinkResult, WalletId } from 'crypto/interface'
import { loadSerializationLib } from 'crypto/lib/cardano/serializationLib'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import * as meld from 'crypto/lib/cardano/wallet/meld'
import * as nami from 'crypto/lib/cardano/wallet/nami'
import * as metamask from 'crypto/lib/ethereum/metamask'
import { changeMetamaskChain } from 'crypto/lib/ethereum/metamask'
import {
  clearStorage,
  updateStorage,
  getStoredAddresses,
  WalletStorage,
} from 'crypto/lib/storage'
import isEqual from 'lodash/isEqual'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useNotifications,
  NotificationType,
} from 'ui/Common/components/Notifications'
import { AddNotificationType } from 'ui/Common/components/Notifications/interface'
import { isAkamonEnabled } from 'ui/Common/config'

type LinkWalletState =
  | { status: 'linked'; address: string; timestamp: number }
  | { status: 'unavailable' | 'unlinked' | 'linking' }

type BaseWallets = {
  meldStorage: Map<number, WalletStorage>
  metamask: LinkWalletState
  nami: LinkWalletState
  eternl: LinkWalletState
}
type StateDispatch = (updateState: (state: BaseWallets) => BaseWallets) => void
type ExternalWallets = Exclude<WalletId, number>

function getInitialState(): BaseWallets {
  return {
    meldStorage: new Map(),
    metamask: { status: 'unavailable' },
    nami: { status: 'unavailable' },
    eternl: { status: 'unavailable' },
  }
}

const walletsContext = createContext<[BaseWallets, StateDispatch]>([
  getInitialState(),
  () => {},
])

function updateMeld(stateDispatch: StateDispatch) {
  stateDispatch((state) => ({ ...state, meldStorage: getStoredAddresses() }))
}

function updateWallet(
  walletId: ExternalWallets,
  stateDispatch: StateDispatch,
  address: string | undefined
) {
  let walletState: LinkWalletState
  if (address) {
    walletState = {
      status: 'linked',
      address,
      timestamp: updateStorage(`${walletId}Storage`),
    }
  } else {
    clearStorage(`${walletId}Storage`)
    walletState = { status: 'unlinked' }
  }

  stateDispatch((state) => ({ ...state, [walletId]: walletState }))
}

async function initContext(
  stateDispatch: StateDispatch,
  addNotification: (notification: AddNotificationType) => string,
  removeNotification: (id: string) => void
) {
  const metamaskPromise = isAkamonEnabled
    ? metamask
        .init((address) => updateWallet('metamask', stateDispatch, address))
        .catch((e) => console.error(e))
    : Promise.resolve()

  await loadSerializationLib()
  updateMeld(stateDispatch)
  const namiPromise = nami
    .init(
      (address) => updateWallet('nami', stateDispatch, address),
      (desiredNetwork) => {
        if (desiredNetwork) {
          addNotification({
            id: 'namiWrongNetwork',
            type: NotificationType.Error,
            content: `Please change Nami network to ${desiredNetwork}!`,
            persist: true,
            disableClose: true,
          })
        } else {
          removeNotification('namiWrongNetwork')
        }
      }
    )
    .catch((e) => console.error(e))

  const eternlPromise = eternl
    .init(
      (address) => updateWallet('eternl', stateDispatch, address),
      (desiredNetwork) => {
        if (desiredNetwork) {
          addNotification({
            id: 'eternlWrongNetwork',
            type: NotificationType.Error,
            content: `Please change Eternl network to ${desiredNetwork}!`,
            persist: true,
            disableClose: true,
          })
        } else {
          removeNotification('eternlWrongNetwork')
        }
      }
    )
    .catch((e) => console.error(e))

  await Promise.all([metamaskPromise, namiPromise, eternlPromise])
}

export function WalletsProvider({ children }: { children: ReactNode }) {
  const { addNotification, removeNotification } = useNotifications()
  const [state, rawStateDispatch] = useState(getInitialState)
  const [loading, setLoading] = useState(true)

  const stateDispatch = useCallback<StateDispatch>((updateState) => {
    rawStateDispatch((state: BaseWallets) => {
      const newState = updateState(state)
      if (isEqual(state, newState)) return state
      return newState
    })
  }, [])

  const loadedState = useMemo(
    () => (loading ? getInitialState() : state),
    [loading, state]
  )

  useEffect(() => {
    initContext(stateDispatch, addNotification, removeNotification).then(() =>
      setLoading(false)
    )
  }, [addNotification, removeNotification, stateDispatch])

  return (
    <walletsContext.Provider value={[loadedState, stateDispatch]}>
      {children}
    </walletsContext.Provider>
  )
}

function useLinkWallet(
  walletId: ExternalWallets,
  link: () => Promise<LinkResult>
): () => Promise<LinkResult> {
  const [, stateDispatch] = useContext(walletsContext)
  const [, setSelectedWallet] = useSelectedWalletId()

  return useCallback(async () => {
    stateDispatch((state) => ({ ...state, [walletId]: { status: 'linking' } }))
    const status = await link()
    if (status !== 'ok')
      stateDispatch((state) => ({
        ...state,
        [walletId]: { status: 'unlinked' },
      }))
    else setSelectedWallet(walletId)

    if (walletId === 'metamask') {
      changeMetamaskChain('polygon')
    }

    return status
  }, [link, setSelectedWallet, stateDispatch, walletId])
}

export function useLinkMetamask(): () => Promise<LinkResult> {
  return useLinkWallet('metamask', metamask.link)
}

export function useLinkNami(): () => Promise<LinkResult> {
  return useLinkWallet('nami', nami.link)
}

export function useLinkEternl(): () => Promise<LinkResult> {
  return useLinkWallet('eternl', eternl.link)
}

export function useCreateMeldWallet(): (
  name: string,
  seedPhrase: string[],
  password: string
) => void {
  const [, stateDispatch] = useContext(walletsContext)
  const [, setSelectedWallet] = useSelectedWalletId()

  return useCallback(
    (name: string, seedPhrase: string[], password: string) => {
      const walletId = meld.importWallet(name, seedPhrase.join(' '), password)
      updateMeld(stateDispatch)
      setSelectedWallet(walletId)
    },
    [setSelectedWallet, stateDispatch]
  )
}

export function useBaseWallets(): BaseWallets {
  return useContext(walletsContext)[0]
}
