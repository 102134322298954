import React from 'react'

export const CancelIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0c5.516.016 9.984 4.484 10 10 0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm2.772 6L9.95 8.822 7.128 6 6 7.128 8.822 9.95 6 12.772 7.128 13.9l2.822-2.822 2.822 2.822 1.128-1.128-2.822-2.822L13.9 7.128 12.772 6z"
      fill={props.fill || '#FC0F0A'}
    />
  </svg>
)
