import { WalletId } from 'crypto/interface'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNotifications } from 'ui/Common/components/Notifications'

const selectedWalletContext = createContext<
  [WalletId | undefined, (walletId: WalletId | undefined) => void]
>([undefined, () => {}])

export function SelectedWalletProvider({ children }: { children: ReactNode }) {
  const { addNotification, removeNotification } = useNotifications()
  const [selectedWallet, rawSetSelectedWallet] = useState<WalletId>()

  const setSelectedWallet = useCallback(
    (newWalletId: WalletId | undefined) =>
      rawSetSelectedWallet((walletId) => {
        if (walletId === newWalletId) return walletId

        localStorage.setItem('selectedWalletId', JSON.stringify(newWalletId))
        return newWalletId
      }),
    []
  )

  useEffect(() => {
    const selectedWalletIdJSON = localStorage.getItem('selectedWalletId')
    const selectedWalletId = selectedWalletIdJSON
      ? JSON.parse(selectedWalletIdJSON)
      : undefined
    setSelectedWallet(selectedWalletId)
  }, [addNotification, removeNotification, setSelectedWallet])

  return (
    <selectedWalletContext.Provider value={[selectedWallet, setSelectedWallet]}>
      {children}
    </selectedWalletContext.Provider>
  )
}

export function useSelectedWalletId(): [
  WalletId | undefined,
  (walletId: WalletId | undefined) => void
] {
  return useContext(selectedWalletContext)
}
