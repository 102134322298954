import { useIntervalPolling } from './util'
import { adaDecimals } from 'crypto/config'
import { FixedStakingData, WalletId } from 'crypto/interface'
import {
  fetchLockedStakingPositions,
  fetchLockedStakingTotalAmountStaked,
} from 'crypto/lib/cardano/connector'
import * as eternl from 'crypto/lib/cardano/wallet/eternl'
import * as nami from 'crypto/lib/cardano/wallet/nami'
import { fromQuantity } from 'crypto/lib/util'
import { addMonths } from 'date-fns'
import differenceInDays from 'date-fns/differenceInDays'
import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { STAKE_POOL_12_APY, STAKE_POOL_6_APY } from 'ui/Staking/utils'

export function useFixedStakingData(walletId: WalletId | undefined): {
  pool6: FixedStakingData
  pool12: FixedStakingData
} {
  const initialPeriod: FixedStakingData = useMemo(
    () => ({
      totalAmountStaked: 0,
      position: undefined,
    }),
    []
  )
  const [pools, setPools] = useState({
    pool6: initialPeriod,
    pool12: initialPeriod,
  })

  const fetchPool = useCallback(
    async (period: 6 | 12): Promise<FixedStakingData> => {
      const totalAmountStaked = await fetchLockedStakingTotalAmountStaked(
        period
      )

      const addresses =
        walletId === 'eternl'
          ? await eternl.getAllAddresses()
          : walletId === 'nami'
          ? [nami.getAddress()]
          : []

      const responsesP = fetchLockedStakingPositions(addresses, period)
      const p = await responsesP
      let position
      if (p) {
        const stakedAmount = fromQuantity(p.staked_amount, adaDecimals)
        const apy = p.period === 12 ? STAKE_POOL_12_APY : STAKE_POOL_6_APY
        const dailyRewardsAmount = stakedAmount.times(apy).div(365)
        const openedAt = new Date(p.opened_at * 1000)
        const closesAt = p.unlocks_at
          ? new Date(p.unlocks_at)
          : addMonths(openedAt, p.period)
        const isStakingEnded = closesAt < new Date()
        const numberOfDays = differenceInDays(Date.now(), openedAt)
        const rewardsAmount = isStakingEnded
          ? stakedAmount.times(
              p.period === 12 ? STAKE_POOL_12_APY : STAKE_POOL_6_APY.div(2)
            )
          : dailyRewardsAmount.times(numberOfDays)

        position = {
          rewardsAmount,
          stakedAmount,
          closesAt,
          isPending: p.status === 'PENDING',
          isStakingEnded,
          isClaiming: false,
          nft: undefined,
          ref: p.ref,
          walletPkh: p.wallet_pkh,
        }
      }

      return {
        totalAmountStaked,
        position,
      }
    },
    [walletId]
  )

  const updateStakingPools = useCallback(async () => {
    setPools({
      pool6: await fetchPool(6),
      pool12: await fetchPool(12),
    })
  }, [fetchPool])

  useIntervalPolling(updateStakingPools)

  return pools
}
