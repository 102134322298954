import { useBalances } from './balance'
import { useSelectedWalletId } from './selectedWallet'
import { useUnitPrices } from './unitPrice'
import { useBaseWallets } from './wallets'
import {
  Asset,
  ChainType,
  LinkWalletStatus,
  Wallet,
  WalletId,
} from 'crypto/interface'
import {
  getAllAssets,
  getEternlWallet,
  getMeldWallet,
  getMetamaskWallet,
  getNamiWallet,
} from 'crypto/lib/balance'
import compact from 'lodash/compact'
import { useCallback, useMemo } from 'react'

export interface MeldState {
  wallets: Wallet[]
  metamaskStatus: LinkWalletStatus
  namiStatus: LinkWalletStatus
  eternlStatus: LinkWalletStatus
  /**
   * Convenience function for checking wallet connection status from WalletId
   * This is much easier to use in loops that distinct "<wallet_name>Status" properties
   */
  isWalletConnected: (walletId: WalletId) => boolean
}

export function useMeldState(): MeldState {
  const unitPrices = useUnitPrices()
  const { meldStorage, metamask, nami, eternl } = useBaseWallets()
  const balances = useBalances()

  const meldWallets: Wallet[] = useMemo(
    () =>
      Array.from(meldStorage.entries()).map(
        ([id, { baseAddress, name, timestamp }]) =>
          getMeldWallet(
            id,
            baseAddress,
            name,
            timestamp,
            balances[id],
            unitPrices
          )
      ),
    [balances, meldStorage, unitPrices]
  )

  const metamaskWallet: Wallet | undefined = useMemo(() => {
    if (metamask.status !== 'linked') return
    return getMetamaskWallet(
      metamask.address,
      metamask.timestamp,
      balances.metamask,
      unitPrices
    )
  }, [balances, metamask, unitPrices])

  const namiWallet: Wallet | undefined = useMemo(() => {
    if (nami.status !== 'linked') return
    return getNamiWallet(
      nami.address,
      nami.timestamp,
      balances.nami,
      unitPrices
    )
  }, [balances.nami, nami, unitPrices])

  const eternlWallet: Wallet | undefined = useMemo(() => {
    if (eternl.status !== 'linked') return
    return getEternlWallet(
      eternl.address,
      eternl.timestamp,
      balances.eternl,
      unitPrices
    )
  }, [balances.eternl, eternl, unitPrices])

  const wallets = useMemo(
    () =>
      compact([...meldWallets, metamaskWallet, namiWallet, eternlWallet]).sort(
        (a, b) => a.timestamp - b.timestamp
      ),
    [eternlWallet, meldWallets, metamaskWallet, namiWallet]
  )
  const isWalletConnected = useCallback(
    (walletId: WalletId) => {
      if (walletId === 'nami') {
        return nami.status === 'linked'
      } else if (walletId === 'metamask') {
        return metamask.status === 'linked'
      } else if (walletId === 'eternl') {
        return eternl.status === 'linked'
      } else {
        return false
      }
    },
    [metamask, nami, eternl]
  )

  return useMemo(
    () => ({
      wallets,
      metamaskStatus: metamask.status,
      namiStatus: nami.status,
      eternlStatus: eternl.status,
      isWalletConnected,
    }),
    [eternl.status, metamask.status, nami.status, wallets, isWalletConnected]
  )
}

export function useSelectedWallet(): [
  Wallet | undefined,
  (wallet: Wallet | undefined) => void
] {
  const { wallets } = useMeldState()
  const [selectedWalletId, setSelectedWalletId] = useSelectedWalletId()
  const selectedWallet = useMemo(
    () => wallets.find((wallet) => wallet.id === selectedWalletId),
    [wallets, selectedWalletId]
  )
  const setSelectedWallet = useCallback(
    (wallet: Wallet | undefined) => setSelectedWalletId(wallet?.id),
    [setSelectedWalletId]
  )
  return [selectedWallet, setSelectedWallet]
}

export function useAllAssets() {
  const unitPrices = useUnitPrices()
  return useMemo(() => getAllAssets(unitPrices), [unitPrices])
}

export function useWalletParams(
  queryParams: Record<string, any>,
  defaultParams: { wallet?: string; chain?: ChainType; asset?: string } = {}
): {
  wallet: Wallet | undefined
  asset: Asset | undefined
  coin: Asset | undefined
  address: string | undefined
} {
  const { wallets } = useMeldState()

  function getParam(param: any, defaultValue?: string) {
    return typeof param === 'string' ? param : defaultValue
  }

  const walletId = getParam(queryParams.wallet, defaultParams.wallet)
  const chain = getParam(queryParams.chain, defaultParams.chain)
  const assetId = getParam(queryParams.asset, defaultParams.asset)

  const wallet = wallets.find((wallet) => wallet.id.toString() === walletId)
  const asset = wallet?.assets.find(
    (asset) => asset.chain === chain && asset.id === assetId
  )
  const coin = wallet?.assets.find(
    (asset) => asset.chain === chain && asset.isCoin
  )
  const address = wallet
    ? coin
      ? wallet.addresses[coin.chain]
      : asset
      ? wallet.addresses[asset.chain]
      : undefined
    : undefined

  return { wallet, asset, coin, address }
}
